import React from "react";
import { observer } from "mobx-react-lite";
import { SalaryBasis } from "erfa-common";
import { Table, CellValue } from "components";
import { CreateRecordType } from "../Framework/models/RecordModel";
import { useErfaStore } from "../Root";
import { UnpublishedRecord } from "../erfa/models";

export const NewDataTable: React.FC = observer(() => {
    const { i18n, unpublishedRecords, allPositions } = useErfaStore();
    const t = i18n.translate;
    allPositions.load();
    const positionsList = allPositions.positionList;

    const defaultColumnDefinition = {
        singleClickEdit: true,
    };

    const onCellChange = (row: object, column: string, value: CellValue) => {
        const actualValue = value as number | SalaryBasis | undefined;

        if (row["isRowCreator"]) {
            unpublishedRecords.add(({
                [column]: actualValue,
            } as unknown) as CreateRecordType<typeof UnpublishedRecord>);
        } else {
            unpublishedRecords.getById(row["id"])?.set(({
                positionId: row["positionId"],
                salaryBasis: row["salaryBasis"],
                age: row["age"],
                yearsOfEmployment: row["yearsOfEmployment"],
                degreeOfEmployment: row["degreeOfEmployment"],
                contractSalary: row["contractSalary"],
                bonusSalary: row["bonusSalary"],
                [column]: actualValue,
            } as unknown) as CreateRecordType<typeof UnpublishedRecord>);
        }
    };

    const onAction = (_rowIndex: number, column: string, row: object) => {
        if (column === "actionRemove") {
            unpublishedRecords.removeById(row["id"]);
        }
    };

    const onValidateValue = (value: CellValue) => {
        if (value === undefined) {
            return "app.inputMissing";
        } else {
            return "";
        }
    };

    const data = unpublishedRecords.items.map((record) => ({
        id: record.id,
        positionId: record.positionId,
        salaryBasis: record.salaryBasis,
        age: record.age,
        salary: record.salary,
        yearsOfEmployment: record.yearsOfEmployment,
        degreeOfEmployment: record.degreeOfEmployment,
        contractSalary: record.contractSalary,
        bonusSalary: record.bonusSalary,

        actionRemove: true,
    }));

    return positionsList.length && unpublishedRecords.loaded ? (
        <Table
            data={data}
            canCreate
            onCellChange={onCellChange}
            onAction={onAction}
            noRowsMessage={t("app.tableNoRows")}
            defaultColumnDefinition={defaultColumnDefinition}
            sizeColumnsToFit
        >
            <Table.DropdownGroupColumn
                width={250}
                column={"positionId"}
                editable
                values={positionsList}
                search
                onValidate={onValidateValue}
            >
                {t("filter.position")}
            </Table.DropdownGroupColumn>

            <Table.DropdownColumn
                width={250}
                column={"salaryBasis"}
                editable
                values={[
                    {
                        display: t("filter.salaryBasis.monthly"),
                        value: "month",
                    },
                    { display: t("filter.salaryBasis.hourly"), value: "hour" },
                    { display: t("filter.salaryBasis.daily"), value: "day" },
                ]}
                onValidate={onValidateValue}
            >
                {t("filter.salaryBasis")}
            </Table.DropdownColumn>

            <Table.NumberColumn
                width={150}
                column={"age"}
                editable
                min={1}
                max={100}
                onValidate={onValidateValue}
            >
                {t("filter.ageBracket")}
            </Table.NumberColumn>

            <Table.NumberColumn
                width={150}
                column={"yearsOfEmployment"}
                editable
                min={0}
                max={100}
                onValidate={onValidateValue}
            >
                {t("filter.yearsOfEmployment")}
            </Table.NumberColumn>

            <Table.NumberColumn
                column={"degreeOfEmployment"}
                editable
                min={1}
                max={100}
                onValidate={onValidateValue}
            >
                {t("filter.degreeOfEmployment")}
            </Table.NumberColumn>

            <Table.NumberColumn
                column={"contractSalary"}
                min={0}
                max={1000000}
                editable
                decimalCount={2}
                onValidate={onValidateValue}
            >
                {t("newData.salary")}
            </Table.NumberColumn>
            <Table.NumberColumn
                column={"salary"}
                editable={false}
                disableNavigableFocus
            >
                {t("results.contractSalary")}
            </Table.NumberColumn>

            <Table.NumberColumn
                column={"bonusSalary"}
                editable
                min={0}
                max={1000000}
                onValidate={onValidateValue}
            >
                {t("results.bonus")}
            </Table.NumberColumn>

            <Table.ActionColumn
                column={"actionRemove"}
                icon={"trash alternate outline"}
                tooltip={t("app.delete")}
            />
        </Table>
    ) : (
        <></>
    );
});
