import React, { useState } from "react";
import { Table } from "components";
import { IPublishedYearsInstance } from "../admin/models";
import { TableSegment } from "../Framework/TableSegment";
import { observer } from "mobx-react-lite";
import { ConfirmDeleteModal } from "./ConfirmDeleteModal";

interface IProps {
    customerPublishedYears: IPublishedYearsInstance;
    translate: (key: string) => string;
}

export const PublishedYearsTable: React.FC<IProps> = observer(
    ({ customerPublishedYears, translate: t }) => {
        const [yearToDelete, setYearToDelete] = useState<number | undefined>(
            undefined
        );
        customerPublishedYears.load();

        const onAction = (_rowIndex: number, column: string, row: object) => {
            if (column === "actionRemove") {
                setYearToDelete(row["year"]);
            }
        };

        return (
            <>
                {yearToDelete && (
                    <ConfirmDeleteModal
                        translate={t}
                        onButtonClick={(button) => {
                            if (button === "primary") {
                                customerPublishedYears.removeById(yearToDelete);

                                setYearToDelete(undefined);
                            } else {
                                setYearToDelete(undefined);
                            }
                        }}
                    />
                )}
                <TableSegment
                    canDelete
                    collection={customerPublishedYears}
                    translate={t}
                    primaryColumn="year"
                    title={t("app.records")}
                >
                    <Table
                        data={customerPublishedYears.items.map(
                            (val, index) => ({
                                id: index,
                                year: val.year,
                                actionRemove: true,
                            })
                        )}
                        onAction={onAction}
                        noRowsMessage={t("app.tableNoRows")}
                    >
                        <Table.NumberColumn
                            flex={1}
                            sort={"desc"}
                            column={"year"}
                            disabledFormat={true}
                            editable={false}
                        >
                            {t("app.record")}
                        </Table.NumberColumn>
                        <Table.ActionColumn
                            column={"actionRemove"}
                            icon={"trash alternate outline"}
                            tooltip={t("app.delete")}
                        />
                    </Table>
                </TableSegment>
            </>
        );
    }
);
