import React from "react";
import FlexView from "react-flexview/lib";

export const ResultsSegmentMessage: React.FC<{ text: string }> = ({ text }) => (
    <FlexView
        vAlignContent="center"
        hAlignContent="center"
        style={{
            textAlign: "center",
            color: "#777",
            fontSize: "20px",
            minHeight: "7em",
        }}
    >
        {text}
    </FlexView>
);
