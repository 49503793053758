import { requests } from "../../requests";

interface IUserLogin {
    id: number;
    username: string;
}

export const AuthenticationApi = {
    login: async (
        customer_number: string,
        username: string,
        password: string
    ) =>
        (await requests.post(
            "/login",
            { customer_number },
            {},
            {
                auth: {
                    username,
                    password,
                },
            }
        )) as Promise<IUserLogin>,

    logout: async () => (await requests.post("/logout", {})) as Promise<void>,
};
