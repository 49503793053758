import React from "react";
import loginLogo from "img/mirus_logo.png";
import { Image } from "semantic-ui-react";

export const MirusLogo: React.FC = () => (
    <Image
        style={{ marginTop: "2em", marginBottom: "3em" }}
        size="medium"
        src={loginLogo}
        centered={true}
    />
);
