import React from "react";
import { SimpleModal, SimpleModalButton } from "components";

const buttonsUnableToDeleteModal = (
    primary: string
): {
    [key: string]: SimpleModalButton;
} => ({
    primary: {
        text: primary,
        type: "primary",
        id: "primary",
    },
});

interface IProps {
    onButtonClick: (id: string) => void;
    message: string;
    translate: (key: string) => string;
}

export const FailedDeleteModal: React.FC<IProps> = ({
    onButtonClick,
    message,
    translate: t,
}) => {
    const button = buttonsUnableToDeleteModal(t("app.ok"));
    return (
        <SimpleModal
            buttons={[button.primary]}
            text={t(message)}
            onButtonClick={onButtonClick}
        />
    );
};
