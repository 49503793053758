import { ViewContainer } from "components";
import React from "react";
import FlexView from "react-flexview/lib";
import { ResultsTotalSegment } from "../../erfa_components";
import { FilterTotalSegment } from "../../erfa_components/FilterTotalSegment";
import { HelpMessage } from "../../erfa_components/HelpMessage";
import { useAdminStore } from "../../Root";

export const AdminFilterTotal: React.FC = () => {
    const adminStore = useAdminStore();
    return (
        <ViewContainer>
            <HelpMessage t={adminStore.i18n.translate} type={"adminFilter"} />
            <FlexView
                marginLeft="-0.5em"
                marginRight="-0.5em"
                marginTop="-0.5em"
                marginBottom="-0.5em"
                wrap
            >
                <FlexView
                    grow={1}
                    basis="1em"
                    marginLeft="0.5em"
                    marginRight="0.5em"
                    marginTop="0.5em"
                    marginBottom="0.5em"
                    style={{ minWidth: "min-content" }}
                    column
                >
                    <FilterTotalSegment isAdmin />
                </FlexView>
                <FlexView
                    grow={2}
                    basis="1em"
                    marginLeft="0.5em"
                    marginRight="0.5em"
                    marginTop="0.5em"
                    marginBottom="0.5em"
                    style={{ minWidth: "min-content" }}
                    column
                >
                    <ResultsTotalSegment isAdmin />
                </FlexView>
            </FlexView>
        </ViewContainer>
    );
};
