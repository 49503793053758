import React, { useCallback, useState } from "react";
import {
    LoggedOutLayout,
    PrimaryButton,
    TextField,
    VerticalSpace,
} from "components";
import { validate, validateRequired } from "../../util/validations";
import { MirusLogo } from "../../erfa_components/MirusLogo";
import { Form, Message } from "semantic-ui-react";
import { useHistory, useLocation } from "react-router-dom";
import { AuthenticationApi } from "../api/Authentication";
import { useErfaStore } from "../../Root";

export const Login: React.FC = () => {
    const store = useErfaStore();

    const t = store.i18n.translate;
    const lsCustomerNumber = localStorage.getItem("customerNumber");

    const history = useHistory();
    const location = useLocation<{ referrer: string }>();

    const [customerNumber, setCustomerNumber] = useState<string>(
        lsCustomerNumber || ""
    );
    const [username, setUsername] = useState<string>("");
    const [password, setPassword] = useState<string>("");

    const [fieldErrors, setFieldErrors] = useState({
        customerNumber: "",
        username: "",
        password: "",
    });

    const [error, setError] = useState<string>("");

    const setFieldError = (field: string, error: string) =>
        setFieldErrors((previousState) => ({
            ...previousState,
            [field]: error,
        }));

    const validateFields: () => boolean = useCallback(() => {
        const customerNumberValid = !validate(
            customerNumber,
            validateRequired,
            (error) => setFieldError("customerNumber", error)
        );
        const usernameValid = !validate(username, validateRequired, (error) =>
            setFieldError("username", error)
        );
        const passwordValid = !validate(password, validateRequired, (error) =>
            setFieldError("password", error)
        );

        return customerNumberValid && usernameValid && passwordValid;
    }, [customerNumber, username, password]);

    const onLogin = useCallback(async (): Promise<void> => {
        setError("");

        const validFields = validateFields();

        if (validFields) {
            try {
                const user = await AuthenticationApi.login(
                    customerNumber,
                    username,
                    password
                );

                store.reset();

                localStorage.setItem("customerNumber", customerNumber);
                localStorage.setItem("user", user.id.toFixed(0));

                const referrer = location.state?.referrer;

                let url =
                    referrer && referrer !== "/login" ? referrer : "/filter";

                history.push(url);
            } catch (e) {
                localStorage.removeItem("user");

                setError("app.loginFailed");
            }
        }
    }, [
        customerNumber,
        validateFields,
        username,
        history,
        location,
        password,
        store,
    ]);

    return (
        <LoggedOutLayout>
            <MirusLogo />
            <Form noValidate>
                <TextField
                    required={false}
                    value={customerNumber}
                    onChange={setCustomerNumber}
                    label={t("app.customerNumber")}
                    maxChars={50}
                    icon={"building"}
                    error={t(fieldErrors.customerNumber)}
                    onValidate={(value: string) =>
                        validate(value, validateRequired, (error) =>
                            setFieldError("customerNumber", error)
                        )
                    }
                    onFocus={() => setFieldError("customerNumber", "")}
                />
                <TextField
                    required={false}
                    value={username}
                    onChange={setUsername}
                    label={t("app.username")}
                    maxChars={50}
                    icon={"user"}
                    error={t(fieldErrors.username)}
                    onValidate={(value: string) =>
                        validate(value, validateRequired, (error) =>
                            setFieldError("username", error)
                        )
                    }
                    onFocus={() => setFieldError("username", "")}
                />
                <TextField
                    required={false}
                    value={password}
                    onChange={setPassword}
                    label={t("app.password")}
                    maxChars={255}
                    type={"password"}
                    icon={"key"}
                    error={t(fieldErrors.password)}
                    onValidate={(value: string) =>
                        validate(value, validateRequired, (error) =>
                            setFieldError("password", error)
                        )
                    }
                    onFocus={() => setFieldError("password", "")}
                />
                <VerticalSpace />
                <PrimaryButton
                    fluid
                    label={t("app.login")}
                    onAction={onLogin}
                />
                <VerticalSpace />
                {error !== "" && <Message negative>{t(error)}</Message>}
            </Form>
        </LoggedOutLayout>
    );
};
