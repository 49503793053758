import { IValidationError, IValidationErrorResponse } from "erfa-common";
import _ from "lodash";
import { StatusCodes } from "http-status-codes";

export interface IValidationErrorKey
    extends Omit<IValidationError, "message"> {}

export type Nullable<T> = T | null;

export const handleErrorResponse = (
    data: IValidationErrorResponse,
    mapErrors: Map<IValidationErrorKey, string>
): string[] => {
    let errors: string[] = [];
    const unknownErrors: string[] = [];

    data.errors.forEach((error) => {
        const errorToFind: IValidationErrorKey = {
            type: error.type,
            field_path: error.field_path,
        };

        const foundError = findWithinErrorMap(errorToFind, mapErrors);
        if (foundError) {
            const message: string = foundError[1];
            errors.push(message);
        } else {
            unknownErrors.push(error.message);
        }
    });

    if (unknownErrors.length) {
        const error: string = unknownErrors.join(", ");
        errors = errors.concat(error);
    }

    return errors;
};

const findWithinErrorMap = (
    key: IValidationErrorKey,
    mapErrors: Map<IValidationErrorKey, string>
): Nullable<[IValidationErrorKey, string]> => {
    let result: Nullable<[IValidationErrorKey, string]> = null;
    Array.from(mapErrors).find((error) => {
        if (_.isEqual(error[0], key)) {
            result = error;
            return true;
        }
        return false;
    });

    return result;
};

export const convertApiError = (
    status: number,
    data: any,
    mapErrors?: Map<IValidationErrorKey, string>
): string[] => {
    switch (status) {
        case StatusCodes.UNAUTHORIZED: {
            break;
        }
        case StatusCodes.UNPROCESSABLE_ENTITY: {
            if (mapErrors) {
                return handleErrorResponse(data, mapErrors);
            }
            break;
        }
        case StatusCodes.FORBIDDEN: {
            return [forbiddenError];
        }
    }
    return [unknownError];
};

export const forbiddenError: string = "app.forbiddenError";
export const unknownError: string = "app.unknownError";
