import React, { useRef } from "react";
import {
    MultiDropdown,
    MultiDropdownProps,
    TranslateFunction,
} from "components";
import "./styles/starCategoryDropdown.css";
import {
    getStarCategoryOptions,
    IStarCategoryOptions,
    star,
} from "./StarCategoryDropdown";

type IProps = Omit<
    MultiDropdownProps & { translate: TranslateFunction },
    "className" | "options" | "placeholder" | "search"
>;

export const MultiStarCategoryDropdown: React.FC<IProps> = (props) => {
    const options = useRef<IStarCategoryOptions[]>(
        getStarCategoryOptions(props.translate)
    );
    return (
        <MultiDropdown
            {...props}
            className={`starCategoryDropdown${
                props.values.length === 0 ? " outline-icons" : ""
            }`}
            options={options.current}
            placeholder={star.repeat(5)}
            search={false}
        />
    );
};
