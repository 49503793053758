import { requests } from "../../requests";
import {
    IUnpublishedRecordsRequest,
    IUnpublishedRecordsResponse,
} from "erfa-common";

export const UnpublishedRecordsApi = {
    all: async () =>
        (await requests.get("/unpublished-records")) as Promise<
            IUnpublishedRecordsResponse[]
        >,

    create: async (newRecord: IUnpublishedRecordsRequest) =>
        (await requests.post("/unpublished-records", newRecord)) as Promise<
            IUnpublishedRecordsResponse
        >,

    update: async (id: number, updateRecord: IUnpublishedRecordsRequest) =>
        (await requests.put(
            `/unpublished-records/${id}`,
            updateRecord
        )) as Promise<IUnpublishedRecordsResponse>,

    remove: async (id: number) =>
        await requests.delete(`/unpublished-records/${id}`),
};
