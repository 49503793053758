import { VALIDATION_ERROR_TYPE } from "erfa-common";
import { IValidationErrorKey } from "../../Framework/api";

export const adminCustomerUserErrors: Map<
    IValidationErrorKey,
    string
> = new Map([
    [
        {
            type: VALIDATION_ERROR_TYPE.UNIQUE,
            field_path: ["username"],
        },
        "app.customerUser.usernameTaken",
    ],
]);
