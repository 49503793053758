import React from "react";
import { TwoColumnLayout } from "components";

import { useAdminStore } from "../../Root";
import { FilterSegment } from "../../erfa_components/FilterSegment";
import { ResultsSegment } from "../../erfa_components/ResultsSegment";
import { HelpMessage } from "../../erfa_components/HelpMessage";

export const AdminFilter: React.FC = () => {
    const adminStore = useAdminStore();
    return (
        <>
            <HelpMessage t={adminStore.i18n.translate} type={"adminFilter"} />
            <TwoColumnLayout>
                <TwoColumnLayout.Left>
                    <FilterSegment store={adminStore} isAdmin />
                </TwoColumnLayout.Left>
                <TwoColumnLayout.Right>
                    <ResultsSegment store={adminStore} />
                </TwoColumnLayout.Right>
            </TwoColumnLayout>
        </>
    );
};
