import { requests } from "../../requests";

export interface IPublishedStatus {
    published: boolean;
    hr30: boolean;
}

export const NewDataApi = {
    errorMap: new Map(),
    resource: "new_data",

    publish: async () => (await requests.post("/publish", {})) as Promise<void>,

    isPublished: async () =>
        (await requests.get("/publish-status")) as Promise<IPublishedStatus>,
};
