import { types, Instance } from "mobx-state-tree";

import {
    SalaryBasisArray,
    SalaryBasis,
    AgeBracketArray,
    AgeBracket,
    YearsOfEmploymentArray,
    YearsOfEmployment,
    DegreeOfEmploymentArray,
    DegreeOfEmployment,
    StarCategoryArray,
    StarCategoryType,
    TourismRegionArray,
    TourismRegionType,
    TourismZoneArray,
    TourismZoneType,
} from "erfa-common";

const Filter = types
    .model({
        positionId: types.maybe(types.number),

        salaryBasis: types.optional(
            types.array(types.enumeration([...SalaryBasisArray])),
            []
        ),

        ageBracket: types.optional(
            types.array(types.enumeration([...AgeBracketArray])),
            []
        ),

        yearsOfEmployment: types.optional(
            types.array(types.enumeration([...YearsOfEmploymentArray])),
            []
        ),

        degreeOfEmployment: types.optional(
            types.array(types.enumeration([...DegreeOfEmploymentArray])),
            []
        ),

        starCategory: types.optional(
            types.array(types.enumeration([...StarCategoryArray])),
            []
        ),

        tourismRegion: types.optional(
            types.array(types.enumeration([...TourismRegionArray])),
            []
        ),

        tourismZone: types.optional(
            types.array(types.enumeration([...TourismZoneArray])),
            []
        ),
    })
    .actions((self) => ({
        setPositionId(positionId: number) {
            self.positionId = positionId;
        },
        setSalaryBasis(salaryBasis: SalaryBasis[]) {
            self.salaryBasis.replace(salaryBasis);
        },
        setAgeBracket(ageBracket: AgeBracket[]) {
            self.ageBracket.replace(ageBracket);
        },
        setYearsOfEmployment(yearsOfEmployment: YearsOfEmployment[]) {
            self.yearsOfEmployment.replace(yearsOfEmployment);
        },
        setDegreeOfEmployment(degreeOfEmployment: DegreeOfEmployment[]) {
            self.degreeOfEmployment.replace(degreeOfEmployment);
        },
        setStarCategory(starCategory: StarCategoryType[]) {
            self.starCategory.replace(starCategory);
        },
        setTourismRegion(tourismRegion: TourismRegionType[]) {
            self.tourismRegion.replace(tourismRegion);
        },
        setTourismZone(tourismZone: TourismZoneType[]) {
            self.tourismZone.replace(tourismZone);
        },
    }));

const FilterErrors = types
    .model({
        position: types.optional(types.string, "app.inputMissing"),
    })
    .actions((self) => ({
        setPosition(error: string) {
            self.position = error;
        },
    }));

export const FilterModel = types.model({
    values: types.optional(Filter, {}),
    errors: types.optional(FilterErrors, {}),
});

export type IFilterInstance = Instance<typeof Filter>;
export type IFilterErrorsInstance = Instance<typeof FilterErrors>;
export type IFilterModelInstance = Instance<typeof FilterModel>;
