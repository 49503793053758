import React from "react";
import { observer } from "mobx-react-lite";
import { Redirect, useParams } from "react-router-dom";

import { useAdminStore } from "Root";
import { AdminCustomerDetail } from "./details/AdminCustomerDetail";
import { AdminCustomerUserDetail } from "./details/AdminCustomerUserDetail";
import { PublishedYearsTable } from "../../erfa_components/PublishedYearsTable";
import { TwoColumnLayout, VerticalSpace } from "components";
import FlexView from "react-flexview/lib";

export const AdminCustomer: React.FC = observer(() => {
    const params = useParams<{ customerId: string }>();
    const customerId: number = Number.parseInt(params.customerId);

    const store = useAdminStore();
    const { customers } = store;
    customers.load();
    const customer = customers.getById(customerId);

    if (customers.loaded && !customer) {
        return <Redirect to="/admin/customers" />;
    }

    customer?.adminCustomerUsers.load();

    const translate = store.i18n.translate;
    return (
        <div style={{ marginTop: "3.57em" }}>
            <TwoColumnLayout>
                <TwoColumnLayout.Left>
                    <FlexView column grow>
                        <AdminCustomerDetail
                            translate={translate}
                            adminCustomer={customer}
                        />
                        {customer && (
                            <>
                                <VerticalSpace big />
                                <AdminCustomerUserDetail
                                    translate={translate}
                                    adminCustomerUsers={
                                        customer.adminCustomerUsers
                                    }
                                />
                            </>
                        )}
                    </FlexView>
                </TwoColumnLayout.Left>
                <TwoColumnLayout.Right>
                    {customer ? (
                        <>
                            <PublishedYearsTable
                                translate={store.i18n.translate}
                                customerPublishedYears={customer.publishedYears}
                            />
                        </>
                    ) : (
                        <></>
                    )}
                </TwoColumnLayout.Right>
            </TwoColumnLayout>
        </div>
    );
});
