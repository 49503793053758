import { VALIDATION_ERROR_TYPE } from "erfa-common";
import { IValidationErrorKey } from "../../Framework/api";

export const adminCustomerErrors: Map<IValidationErrorKey, string> = new Map([
    [
        {
            type: VALIDATION_ERROR_TYPE.UNIQUE,
            field_path: ["number"],
        },
        "app.customers.numberUsed",
    ],
]);
