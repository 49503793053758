import { CollapsibleSegment, PrimaryButton } from "components";
import { observer } from "mobx-react-lite";
import moment from "moment";
import React, { useEffect } from "react";
import FlexView from "react-flexview";
import { IAdminResultTotalInstance } from "../admin/models";
import { useAdminStore, useErfaStore } from "../Root";
import { ResultsTotalTable, ResultTotalColumnNames } from "./ResultsTotalTable";
import "./styles/quality.css";

interface IProps {
    isAdmin?: boolean;
}
export const ResultsTotalSegment: React.FC<IProps> = observer(({ isAdmin }) => {
    const { i18n, resultsTotal, positions } = isAdmin
        ? useAdminStore()
        : useErfaStore();

    const t = i18n.translate;

    const lastYear = moment().year() - 1;
    const adminResultsTotal = resultsTotal as IAdminResultTotalInstance;

    useEffect(() => {
        if (isAdmin) {
            adminResultsTotal.setResultsCopied(false);
        }
    }, [isAdmin, i18n.language, adminResultsTotal]);

    return (
        <FlexView column grow>
            <CollapsibleSegment
                collapsible
                open
                title={`${t("results.all")} ${lastYear}`}
                footer={
                    isAdmin &&
                    resultsTotal.items.length > 0 && (
                        <PrimaryButton
                            label={
                                adminResultsTotal.resultsCopied
                                    ? t("results.copied")
                                    : t("results.copy")
                            }
                            onAction={() => {
                                const restulsRows = resultsTotal.items.map(
                                    (result) =>
                                        ResultTotalColumnNames.map(
                                            ({ key }) => {
                                                if (key === "quality") {
                                                    return t(
                                                        `quality.${result[key]}`
                                                    );
                                                } else if (key === "position") {
                                                    return positions.findPositionById(
                                                        result.positionId
                                                    )?.description;
                                                }

                                                return result[key];
                                            }
                                        ).join("\t")
                                );
                                navigator.clipboard.writeText(
                                    restulsRows.join("\n")
                                );
                                adminResultsTotal.setResultsCopied(true);
                            }}
                        />
                    )
                }
            >
                <div style={{ minHeight: "10em" }}>
                    <ResultsTotalTable isAdmin={isAdmin} />
                </div>
            </CollapsibleSegment>
        </FlexView>
    );
});
