import { IResultsResponse } from "erfa-common";
import { requests } from "../../requests";

export const ResultsApi = {
    getCompanyResults: async (queryParams: string) => {
        return (await requests.get(
            `/company-results?${queryParams}`,
            {}
        )) as Promise<IResultsResponse>;
    },

    getAllResults: async (queryParams: string, isAdmin?: boolean) => {
        const adminUrl = isAdmin ? "/admin" : "";

        return (await requests.get(
            `${adminUrl}/results?${queryParams}`,
            {}
        )) as Promise<IResultsResponse>;
    },
};
