import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { ModalDetail, SimpleModal, TextField } from "components";
import { createDetail } from "../../../Framework/Detail";
import { validateRequired } from "../../../util/validations";
import { AdminCustomerUser, IAdminCustomerUsersInstance } from "../../models";

interface IAdminCustomerUserIProps {
    adminCustomerUsers: IAdminCustomerUsersInstance;
    translate: (key: string) => string;
}

export type OpenModal = "none" | "change_password" | "password_changed";

export const AdminCustomerUserDetail: React.FC<IAdminCustomerUserIProps> = observer(
    ({ adminCustomerUsers, translate: t }) => {
        const [openModal, setOpenModal] = useState<OpenModal>("none");
        const [newPassword, setNewPassword] = useState("");
        const [newPasswordAgain, setNewPasswordAgain] = useState("");
        const [newPasswordError, setNewPasswordError] = useState("");
        const [newPasswordAgainError, setNewPasswordAgainError] = useState("");
        const [passwordChangeError, setPasswordChangeError] = useState<
            string[] | undefined
        >(undefined);

        const AdminCustomerUserDetail = createDetail(AdminCustomerUser);
        const emptyUsers = adminCustomerUsers.items.length === 0;

        const adminCustomerUser = !emptyUsers
            ? adminCustomerUsers.items[0]
            : undefined;

        const validatePasswords = () => {
            const passwordError = validateRequired(newPassword);
            const passwordAgainError = validateRequired(newPasswordAgain);

            setNewPasswordError(t(passwordError));
            setNewPasswordAgainError(t(passwordAgainError));
            const validPasswords = !passwordError && !passwordAgainError;
            const equalsPasswords = newPassword === newPasswordAgain;
            if (validPasswords && equalsPasswords) {
                return true;
            } else {
                if (validPasswords && !equalsPasswords) {
                    setPasswordChangeError([t("app.passwordEquals")]);
                }
                return false;
            }
        };

        const closeModal = () => {
            setNewPassword("");
            setNewPasswordAgain("");
            setNewPasswordError("");
            setNewPasswordAgainError("");
            setPasswordChangeError(undefined);
            setOpenModal("none");
        };

        return (
            <>
                {openModal === "change_password" && (
                    <ModalDetail
                        translate={t}
                        title={t("app.passwordChange")}
                        edited={true}
                        errors={passwordChangeError}
                        onCancel={() => {
                            closeModal();
                        }}
                        onSave={() => {
                            if (validatePasswords()) {
                                adminCustomerUser!.actions.setPassword(
                                    newPassword
                                );
                                closeModal();
                                setOpenModal("password_changed");
                            }
                        }}
                        buttonsLabel={{
                            save: t("app.save"),
                            cancel: t("app.cancel"),
                        }}
                    >
                        <TextField
                            label={t("app.newPassword")}
                            value={""}
                            onChange={(value: string) => {
                                setNewPassword(value);
                            }}
                            required={true}
                            type={"password"}
                            error={newPasswordError}
                            onValidate={(value: string) => {
                                setNewPasswordError(
                                    t(validateRequired(newPassword))
                                );
                            }}
                        />
                        <TextField
                            label={t("app.newPasswordAgain")}
                            value={""}
                            onChange={(value: string) => {
                                setNewPasswordAgain(value);
                            }}
                            required={true}
                            type={"password"}
                            error={newPasswordAgainError}
                            onValidate={(value: string) => {
                                setNewPasswordAgainError(
                                    t(validateRequired(newPasswordAgain))
                                );
                            }}
                        />
                    </ModalDetail>
                )}
                {openModal === "password_changed" && (
                    <SimpleModal
                        text={t("info.passwordChanged")}
                        buttons={[{ id: "ok", text: t("app.ok") }]}
                        onButtonClick={() => {
                            setOpenModal("none");
                        }}
                    ></SimpleModal>
                )}
                <AdminCustomerUserDetail
                    collection={emptyUsers ? adminCustomerUsers : undefined}
                    title={t("app.user")}
                    model={!emptyUsers ? adminCustomerUser : undefined}
                    canEdit={!emptyUsers}
                    canDelete={true}
                    translate={t}
                    canCreate={emptyUsers}
                    menuInHeader={!emptyUsers}
                    displayButtonsAfterChange={true}
                    onValidate={(fieldValues) => {
                        return fieldValues.password !==
                            fieldValues.passwordAgain
                            ? [t("app.passwordEquals")]
                            : [];
                    }}
                >
                    <AdminCustomerUserDetail.TextField
                        dataprovider="lastname"
                        label={t("app.name")}
                        maxChars={200}
                        required
                        onValidate={(value: string) =>
                            t(validateRequired(value))
                        }
                    />
                    <AdminCustomerUserDetail.TextField
                        dataprovider="firstname"
                        label={t("app.firstname")}
                        maxChars={200}
                        required
                        onValidate={(value: string) =>
                            t(validateRequired(value))
                        }
                    />
                    <AdminCustomerUserDetail.TextField
                        dataprovider="username"
                        label={t("app.username")}
                        maxChars={50}
                        required
                        onValidate={(value: string) =>
                            t(validateRequired(value))
                        }
                    />
                    {adminCustomerUser ? (
                        <AdminCustomerUserDetail.ButtonField
                            label={t("app.passwordChange")}
                            onAction={() => {
                                setOpenModal("change_password");
                            }}
                            primary
                        />
                    ) : (
                        [
                            <AdminCustomerUserDetail.TextField
                                dataprovider="password"
                                label={t("app.password")}
                                required
                                type={"password"}
                                onValidate={(value: string) => {
                                    return t(validateRequired(value));
                                }}
                                key={"key_password"}
                            />,
                            <AdminCustomerUserDetail.TextField
                                dataprovider="passwordAgain"
                                label={t("app.passwordAgain")}
                                required
                                type={"password"}
                                onValidate={(value: string) =>
                                    t(validateRequired(value))
                                }
                                key={"key_password_again"}
                            />,
                        ]
                    )}
                </AdminCustomerUserDetail>
            </>
        );
    }
);
