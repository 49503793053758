import { flow, Instance, types } from "mobx-state-tree";
import { ModelInterface } from "../../Framework/models/RecordModel";
import { WeeklyHoursApi } from "../api";
import { WeeklyHours as WeeklyHoursType } from "erfa-common";

export const WeeklyHours = types
    .model({
        value: types.maybe(types.number),
        loadState: types.optional(
            types.enumeration(["unloaded", "loading", "loaded"]),
            "unloaded"
        ),
    })
    .actions((self) => ({
        load: flow(function* () {
            if (self.loadState === "unloaded") {
                try {
                    self.loadState = "loading";
                    const response = yield WeeklyHoursApi.get();
                    if (response) {
                        self.value = response.weekly_hours;
                    }
                } catch (e) {
                    return;
                } finally {
                    self.loadState = "loaded";
                }
            }
        }),
        set: flow(function* (value: WeeklyHoursType) {
            try {
                const response = yield WeeklyHoursApi.update({
                    weekly_hours: value,
                });
                if (response) {
                    self.value = response.weekly_hours;
                }
            } catch (e) {}
        }),
    }));

export type IWeeklyHoursModel = ModelInterface<typeof WeeklyHours>;
export type IWeeklyHoursInstance = Instance<typeof WeeklyHours>;
