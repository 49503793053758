import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { useAdminStore } from "../../Root";
import { AdminAuthenticationApi } from "../api/AdminAuthentication";

export const AdminLogout: React.FC = () => {
    const store = useAdminStore();

    useEffect(() => {
        (async () => {
            try {
                await AdminAuthenticationApi.logout();
            } catch (e) {
            } finally {
                store.reset();
                localStorage.removeItem("admin_user");
            }
        })();
    }, [store]);

    return <Redirect to={"/admin/login"} />;
};
