import { applySnapshot, Instance, types } from "mobx-state-tree";
import { FilterModel, FilterTotalModel, Positions } from "../../erfa/models";
import { I18n } from "../../i18n/I18n";
import { getLanguage } from "../../util/language";
import { AdminCustomers } from "./AdminCustomers";
import { AdminOauthClients } from "./AdminOauthClients";
import { adminResultsDefault, AdminResults } from "./Results";
import { adminResultsTotalDefault, AdminResultsTotal } from "./ResultsTotal";

export const AdminModel = types
    .model({
        i18n: types.optional(I18n, {
            language: getLanguage(),
        }),
        results: types.optional(AdminResults, adminResultsDefault),
        resultsTotal: types.optional(
            AdminResultsTotal,
            adminResultsTotalDefault
        ),
        filter: types.optional(FilterModel, {}),
        filterTotal: types.optional(FilterTotalModel, {}),
        positions: types.optional(Positions, {}),
        customers: types.optional(AdminCustomers, {}),
        clients: types.optional(AdminOauthClients, {}),
        user: types.maybe(types.number),
    })
    .actions((self) => ({
        reset() {
            applySnapshot(self, { i18n: { ...self.i18n } });
        },
        setUser(id: number | undefined) {
            self.user = id;
        },
    }));

export type IAdminModel = Instance<typeof AdminModel>;
