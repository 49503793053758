import Axios, { AxiosRequestConfig } from "axios";
import { StatusCodes } from "http-status-codes";
import history from "erfaHistory";

const api = Axios.create({
    baseURL:
        process.env.NODE_ENV === "development"
            ? "http://localhost:3001"
            : "/api",
    timeout: 2500,
    withCredentials: true,
});

export const requests = {
    get: async (url: string, params?: any, config?: AxiosRequestConfig) => {
        return await api
            .get(url, { ...config, params })
            .then((res) => res.data)
            .catch((e) => {
                if (e.response?.status === StatusCodes.UNAUTHORIZED) {
                    handleError401();
                }

                throw e;
            });
    },
    post: async (
        url: string,
        data: any,
        params?: any,
        config?: AxiosRequestConfig
    ) => {
        return await api
            .post(url, data, { ...config, params })
            .then((res) => res.data)
            .catch((e) => {
                if (
                    !url.endsWith("/login") &&
                    e.response?.status === StatusCodes.UNAUTHORIZED
                ) {
                    handleError401();
                }
                throw e;
            });
    },
    put: async (
        url: string,
        data: any,
        params?: any,
        config?: AxiosRequestConfig
    ) => {
        return await api
            .put(url, data, { ...config, params })
            .then((res) => res.data)
            .catch((e) => {
                if (e.response?.status === StatusCodes.UNAUTHORIZED) {
                    handleError401();
                }
                throw e;
            });
    },
    delete: async (url: string, params?: any, config?: AxiosRequestConfig) => {
        return await api
            .delete(url, { ...config, params })
            .then((res) => res.status === StatusCodes.NO_CONTENT)
            .catch((e) => {
                if (e.response?.status === StatusCodes.UNAUTHORIZED) {
                    handleError401();
                }

                throw e;
            });
    },
};

const handleError401 = () => {
    const currentUrl = history.location.pathname;
    const isAdmin = currentUrl.startsWith("/admin");
    const logoutUrl = isAdmin ? "/admin/logout" : "/logout";

    localStorage.removeItem(isAdmin ? "admin_user" : "user");
    history.push(
        logoutUrl,
        currentUrl !== logoutUrl ? { referrer: currentUrl } : undefined
    );
};
