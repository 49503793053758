import { flow, types, Instance } from "mobx-state-tree";
import { Language } from "erfa-common";
import { convertApiError } from "../Framework/api";
import { i18n_messagesApi } from "./i18n_messagesApi";
import { DateFormatPeriod } from "components";

export const supportedLanguages: string[] = ["de", "fr", "it"];

const I18nMessage = types
    .model({
        errors: types.optional(types.array(types.string), []),
        values: types.optional(types.model({}), {}),
        loaded: types.optional(types.boolean, false),
    })
    .actions((self) => ({
        load: flow(function* (language: Language) {
            if (!self.loaded) {
                let values: object | null = null;
                try {
                    values = yield i18n_messagesApi.all(language);
                    self.loaded = true;
                } catch (e) {
                    if (e.response) {
                        const { status, data } = e.response;
                        self.errors.replace(convertApiError(status, data));
                    } else {
                        console.log(e);
                        self.errors.replace(["app.unknownError"]);
                    }
                    return;
                }
                if (values) {
                    Object.keys(values).forEach(
                        (key) => (self.values[key] = values![key])
                    );
                    self.errors.replace([]);
                }
            }
        }),
    }));

export const I18n = types
    .model({
        de: types.optional(I18nMessage, {}),
        fr: types.optional(I18nMessage, {}),
        it: types.optional(I18nMessage, {}),
        language: types.enumeration(supportedLanguages),
    })
    .actions((self) => ({
        setLanguage(language: Language) {
            self.language = language;
            localStorage.setItem("language", language);
        },
    }))
    .views((self) => ({
        translate: (key: string): string => {
            const found = self[self.language].values[key];
            if (found) {
                return found;
            } else {
                return key;
            }
        },
    }))
    .views((self) => ({
        translateDateFormat(
            date: moment.Moment,
            period: DateFormatPeriod,
            short?: boolean
        ): string {
            const indexSwitch = {
                month: date.month(),
                day: date.day(),
            };

            const keywordSwitch = {
                month: [
                    "january",
                    "february",
                    "march",
                    "april",
                    "may",
                    "june",
                    "july",
                    "august",
                    "september",
                    "october",
                    "november",
                    "december",
                ],
                day: [
                    "sunday",
                    "monday",
                    "tuesday",
                    "wednesday",
                    "thursday",
                    "friday",
                    "saturday",
                ],
            };

            const form = short ? "short" : "normal";
            const keyword = keywordSwitch[period][indexSwitch[period]];
            const key = `date.${form}.${keyword}`;

            return self.translate(key);
        },
    }))
    .actions((self) => ({
        load: flow(function* () {
            yield self[self.language].load(self.language);
        }),
    }));

export type II18nMessageInstance = Instance<typeof I18nMessage>;
export type II18nInstance = Instance<typeof I18n>;
