import React from "react";
import { TwoColumnLayout } from "components";
import { FilterSegment, ResultsSegment } from "../../erfa_components";
import { useErfaStore } from "../../Root";
import { HelpMessage } from "../../erfa_components/HelpMessage";
import { PublishMessage } from "../../erfa_components/PublishMessage";

export const Filter: React.FC = () => {
    const store = useErfaStore();
    const { newData } = store;
    if (!newData.published) {
        return <PublishMessage t={store.i18n.translate} />;
    }
    return (
        <>
            <HelpMessage t={store.i18n.translate} type={"filter"} />
            <TwoColumnLayout>
                <TwoColumnLayout.Left>
                    <FilterSegment store={store} />
                </TwoColumnLayout.Left>
                <TwoColumnLayout.Right>
                    <ResultsSegment store={store} />
                </TwoColumnLayout.Right>
            </TwoColumnLayout>
        </>
    );
};
