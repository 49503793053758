import React from "react";
import { Form } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { ResultsApi } from "../erfa/api";
import { IErfaModel } from "../erfa/models";
import { MultiStarCategoryDropdown } from "./MultiStarCategoryDropdown";
import _ from "lodash";

import {
    SalaryBasis,
    AgeBracket,
    YearsOfEmployment,
    DegreeOfEmployment,
    StarCategoryType,
    TourismRegionType,
    TourismZoneType,
    TourismRegionArray,
    TourismZoneArray,
} from "erfa-common";

import {
    CollapsibleSegment,
    MultiDropdown,
    DropdownOption,
    DropdownValue,
    DropdownGroup,
} from "components";
import { IAdminModel } from "../admin/models";
import { createResultQueryParams } from "../util/filterQueryParams";

interface IProps {
    store: IErfaModel | IAdminModel;
    open?: boolean;
    onClose?: () => void;
    onOpen?: () => void;
    children?: React.ReactNode | React.ReactNode[];
    isAdmin?: boolean;
}

export const FilterSegment: React.FC<IProps> = observer(
    ({ open, onClose, onOpen, store, isAdmin }) => {
        const {
            filter: filterStore,
            results: resultsStore,
            i18n,
            positions,
        } = store;

        positions.load();
        const t = i18n.translate;
        const NO_RESULTS_MESSAGE_KEY = "app.noResultsFound";
        const INVALID_FILTER_MESSAGE_KEY = "results.invalidFilter";

        const salaryBasisOptions: DropdownOption[] = [
            {
                display: t("filter.salaryBasis.monthly"),
                value: "month",
            },
            {
                display: t("filter.salaryBasis.hourly"),
                value: "hour",
            },
            {
                display: t("filter.salaryBasis.daily"),
                value: "day",
            },
        ];

        const tourismRegionOptions = TourismRegionArray.map(
            (tourismRegion): DropdownOption => ({
                display: t(`tourismusregion.${tourismRegion}`),
                value: tourismRegion,
            })
        );

        const tourismZoneOptions = TourismZoneArray.map(
            (tourismZone): DropdownOption => ({
                display: t(`tourismuszone.${tourismZone}`),
                value: tourismZone,
            })
        );

        const fetchResults = async () => {
            if (!filterStore.values.positionId) {
                resultsStore["company"]?.setErrorMessage(
                    INVALID_FILTER_MESSAGE_KEY
                );
                resultsStore.all.setErrorMessage(INVALID_FILTER_MESSAGE_KEY);
                return;
            }

            try {
                const queryParams = createResultQueryParams(filterStore.values);

                if (!isAdmin) {
                    const companyResults = await ResultsApi.getCompanyResults(
                        queryParams
                    );

                    resultsStore["company"]?.salary.setResult(
                        companyResults.result!.contractSalary
                    );
                    resultsStore["company"]?.bonus.setResult(
                        companyResults.result!.bonusSalary
                    );
                    resultsStore["company"]?.setQuality(
                        companyResults.result!.quality
                    );
                    resultsStore["company"]?.setErrorMessage("");
                }

                const allResults = await ResultsApi.getAllResults(
                    queryParams,
                    isAdmin
                );

                if (!_.isEmpty(allResults)) {
                    resultsStore.all.salary.setResult(
                        allResults.result!.contractSalary
                    );
                    resultsStore.all.bonus.setResult(
                        allResults.result!.bonusSalary
                    );
                    resultsStore.all.setQuality(allResults.result!.quality);
                    resultsStore.all.setErrorMessage("");
                } else {
                    resultsStore.all.setErrorMessage(NO_RESULTS_MESSAGE_KEY);
                }
            } catch (error) {
                resultsStore["company"]?.setErrorMessage(
                    NO_RESULTS_MESSAGE_KEY
                );
                resultsStore.all.setErrorMessage(NO_RESULTS_MESSAGE_KEY);
            }
        };

        return (
            <CollapsibleSegment
                title={t("app.filter")}
                collapsible
                open={open !== false}
                onClose={onClose}
                onOpen={onOpen}
            >
                <Form noValidate>
                    <DropdownGroup
                        label={t("filter.position")}
                        required
                        search
                        value={filterStore.values.positionId}
                        options={positions.positionList}
                        error={t(filterStore.errors.position)}
                        onChange={(value: DropdownValue | undefined) => {
                            filterStore.values.setPositionId(value as number);
                            fetchResults();
                        }}
                        onValidate={(value: DropdownValue | undefined) => {
                            filterStore.errors.setPosition(
                                value === undefined ? "app.inputMissing" : ""
                            );
                        }}
                        onFocus={() => {
                            filterStore.errors.setPosition("");
                        }}
                        noResultsMessage={t(NO_RESULTS_MESSAGE_KEY)}
                    />
                    <MultiDropdown
                        label={t("filter.salaryBasis")}
                        values={[...filterStore.values.salaryBasis]}
                        options={salaryBasisOptions}
                        onChange={(values: DropdownValue[]) => {
                            filterStore.values.setSalaryBasis(
                                values as SalaryBasis[]
                            );
                            fetchResults();
                        }}
                        search={false}
                    />
                    <MultiDropdown
                        label={t("filter.ageBracket")}
                        values={[...filterStore.values.ageBracket]}
                        options={ageBracketOptions}
                        onChange={(values: DropdownValue[]) => {
                            filterStore.values.setAgeBracket(
                                values as AgeBracket[]
                            );
                            fetchResults();
                        }}
                        search={false}
                    />
                    <MultiDropdown
                        label={t("filter.yearsOfEmployment")}
                        values={[...filterStore.values.yearsOfEmployment]}
                        options={yearsOfEmploymentOptions}
                        onChange={(values: DropdownValue[]) => {
                            filterStore.values.setYearsOfEmployment(
                                values as YearsOfEmployment[]
                            );
                            fetchResults();
                        }}
                        search={false}
                    />
                    <MultiDropdown
                        label={t("filter.degreeOfEmployment")}
                        values={[...filterStore.values.degreeOfEmployment]}
                        options={degreeOfEmploymentOptions}
                        search={false}
                        onChange={(values: DropdownValue[]) => {
                            filterStore.values.setDegreeOfEmployment(
                                values as DegreeOfEmployment[]
                            );
                            fetchResults();
                        }}
                    />
                    <MultiStarCategoryDropdown
                        label={t("filter.starRating")}
                        values={[...filterStore.values.starCategory]}
                        onChange={(values: DropdownValue[]) => {
                            filterStore.values.setStarCategory(
                                values as StarCategoryType[]
                            );
                            fetchResults();
                        }}
                        translate={i18n.translate}
                    />
                    <MultiDropdown
                        label={t("filter.tourismRegion")}
                        values={[...filterStore.values.tourismRegion]}
                        options={tourismRegionOptions}
                        search
                        noResultsMessage={t(NO_RESULTS_MESSAGE_KEY)}
                        onChange={(values: DropdownValue[]) => {
                            filterStore.values.setTourismRegion(
                                values as TourismRegionType[]
                            );

                            fetchResults();
                        }}
                    />
                    <MultiDropdown
                        label={t("filter.tourismZone")}
                        values={[...filterStore.values.tourismZone]}
                        options={tourismZoneOptions}
                        search={false}
                        onChange={(values: DropdownValue[]) => {
                            filterStore.values.setTourismZone(
                                values as TourismZoneType[]
                            );

                            fetchResults();
                        }}
                    />
                </Form>
            </CollapsibleSegment>
        );
    }
);

const ageBracketOptions: DropdownOption[] = [
    {
        display: "< 30",
        value: "0-29",
    },
    {
        display: "30 - 39",
        value: "30-39",
    },
    {
        display: "40 - 49",
        value: "40-49",
    },
    {
        display: "50+",
        value: "50-100",
    },
];

const yearsOfEmploymentOptions: DropdownOption[] = [
    {
        display: "< 5",
        value: "0-4",
    },
    {
        display: "5 - 10",
        value: "5-10",
    },
    {
        display: "11 - 15",
        value: "11-15",
    },
    {
        display: "16 - 19",
        value: "16-19",
    },
    {
        display: "20+",
        value: "20-100",
    },
];

const degreeOfEmploymentOptions: DropdownOption[] = [
    {
        display: "0 - 10%",
        value: "0-10",
    },
    {
        display: "11 - 20%",
        value: "11-20",
    },
    {
        display: "21 - 30%",
        value: "21-30",
    },
    {
        display: "31 - 40%",
        value: "31-40",
    },
    {
        display: "41 - 50%",
        value: "41-50",
    },
    {
        display: "51 - 60%",
        value: "51-60",
    },
    {
        display: "61 - 70%",
        value: "61-70",
    },
    {
        display: "71 - 80%",
        value: "71-80",
    },
    {
        display: "81 - 90%",
        value: "81-90",
    },
    {
        display: "91 - 100%",
        value: "91-100",
    },
];
