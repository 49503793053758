import { IAdminCustomerRequest, IAdminCustomerResponse } from "erfa-common";
import { requests } from "../../requests";

export const AdminCustomersApi = {
    all: async () =>
        (await requests.get(`/admin/customers`, {})) as Promise<
            IAdminCustomerResponse[]
        >,
    add: async (customer: IAdminCustomerRequest) =>
        requests.post("/admin/customers", customer) as Promise<
            IAdminCustomerResponse
        >,
    update: async (customerId: number, customer: IAdminCustomerRequest) =>
        requests.put(`/admin/customers/${customerId}`, customer) as Promise<
            IAdminCustomerResponse
        >,
    delete: async (customerId: number) =>
        requests.delete(`/admin/customers/${customerId}`) as Promise<boolean>,
};
