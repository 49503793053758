import { Instance, SnapshotIn, types, flow, getParent } from "mobx-state-tree";
import { createRestCollectionModel } from "../../Framework/models/CollectionModel";
import {
    createRestRecordModel,
    ModelInterface,
} from "../../Framework/models/RecordModel";
import { AdminOauthClientsApi } from "../api";

const AdminOauthClientActions = types
    .model({ transactionId: types.optional(types.string, "") })
    .actions((self) => ({
        authorize: flow(function* () {
            const client = getParent(self);
            const result = yield AdminOauthClientsApi.authorize(
                client["id"],
                client["redirectUri"]
            );
            self.transactionId = result.transactionId;
        }),
        allow: flow(function* () {
            yield AdminOauthClientsApi.decisionAllow(self.transactionId);
        }),
        deny: flow(function* () {
            yield AdminOauthClientsApi.decisionDeny(self.transactionId);
        }),
    }));

export const AdminOauthClient = createRestRecordModel(
    "id",
    "string",
    {
        name: types.string,
        redirectUri: types.string,
    },
    {
        actions: types.optional(AdminOauthClientActions, {}),
    },
    {
        errorMap: new Map(),
        update: (_ids, _values) => {
            throw new Error("unsuported update");
        },
    }
);

export const AdminOauthClients = createRestCollectionModel(
    AdminOauthClient,
    "id",
    "string",
    {
        resource: "oauth_client",
        errorMap: new Map(),
        all: async () => AdminOauthClientsApi.all(),
        remove: (ids) => AdminOauthClientsApi.delete(ids[0] as string),
        create: (_ids, values) =>
            AdminOauthClientsApi.add({ ...values, secret: "secret" }),
    }
);

export const AdminOauthClientsDefault: SnapshotIn<typeof AdminOauthClients> = {
    errors: [],
};

export type IAdminOauthClient = ModelInterface<typeof AdminOauthClient>;
export type IAdminOauthClientInstance = Instance<typeof AdminOauthClient>;
