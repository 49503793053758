import { IAdminPositionsResponse, Language } from "erfa-common";
import { requests } from "../../requests";

export const AllPositionApi = {
    all: async (lang: Language) => {
        const adminUrl = window.location.pathname.startsWith("/admin")
            ? "/admin"
            : "";

        return (await requests.get(
            `${adminUrl}/all-positions?lang=${lang}`,
            {}
        )) as Promise<IAdminPositionsResponse[]>;
    },
};
