import React, { useState } from "react";
import { Icon, Message } from "semantic-ui-react";
import { VerticalSpace } from "components";

export type helpMessageType = "filter" | "adminFilter" | "newData";

interface IProps {
    t: (key: string) => string;
    type: helpMessageType;
}

interface locStorageItem {
    filter?: boolean;
    adminFilter?: boolean;
    newData?: boolean;
}

export const HelpMessage: React.FC<IProps> = ({ t, type }) => {
    const contentKey: { [key in helpMessageType]: string } = {
        filter: "helpMessage.content.filter",
        adminFilter: "helpMessage.content.filter",
        newData: "helpMessage.content.newData",
    };

    const locStorageItemName = "helpMessageOpen";

    const lsHelpOpenState = localStorage.getItem(locStorageItemName);
    let isOpen = true;
    if (lsHelpOpenState !== null) {
        try {
            const lsItem: locStorageItem = JSON.parse(lsHelpOpenState);
            isOpen = lsItem[type] !== false;
        } catch (e) {}
    }

    const [open, setOpen] = useState<boolean>(isOpen);

    return (
        <div style={{ position: "relative" }}>
            {
                <Icon
                    data-testid={"helpMsgIcon"}
                    style={{
                        position: "absolute",
                        top: "1em",
                        right: "1em",
                        zIndex: 2,
                        cursor: "pointer",
                        color: "#0e566c",
                    }}
                    name={open ? "close" : "angle double down"}
                    onClick={() => {
                        let state = { [type]: !open };

                        if (lsHelpOpenState) {
                            try {
                                state = {
                                    ...JSON.parse(lsHelpOpenState),
                                    ...state,
                                };
                            } catch (e) {}
                        }
                        localStorage.setItem(
                            locStorageItemName,
                            JSON.stringify(state)
                        );
                        setOpen(!open);
                    }}
                />
            }
            <Message style={{ margin: 0, zIndex: 1 }} info>
                <Message.Header>{t("helpMessage.title")}</Message.Header>
                {open && (
                    <Message.List>
                        {t(contentKey[type])
                            .trim()
                            .split("\n")
                            .map((content, index) => (
                                <Message.Item key={index}>
                                    {content}
                                </Message.Item>
                            ))}
                    </Message.List>
                )}
            </Message>
            <VerticalSpace small />
        </div>
    );
};
