import React, { useCallback, useState } from "react";
import {
    LoggedOutLayout,
    PrimaryButton,
    TextField,
    VerticalSpace,
} from "components";
import { validate, validateRequired } from "../../util/validations";
import { MirusLogo } from "../../erfa_components/MirusLogo";
import { Form, Message } from "semantic-ui-react";
import { useHistory, useLocation } from "react-router-dom";
import { AdminAuthenticationApi } from "../api/AdminAuthentication";
import { useAdminStore } from "../../Root";

export const AdminLogin: React.FC = () => {
    const store = useAdminStore();

    const t = store.i18n.translate;

    const history = useHistory();
    const location = useLocation<{ referrer: string }>();

    const [username, setUsername] = useState<string>("");
    const [password, setPassword] = useState<string>("");

    const [fieldErrors, setFieldErrors] = useState({
        username: "",
        password: "",
    });

    const [error, setError] = useState<string>("");

    const setFieldError = (field: string, error: string) =>
        setFieldErrors((previousState) => ({
            ...previousState,
            [field]: error,
        }));

    const validateFields: () => boolean = useCallback(() => {
        const usernameValid = !validate(username, validateRequired, (error) =>
            setFieldError("username", error)
        );
        const passwordValid = !validate(password, validateRequired, (error) =>
            setFieldError("password", error)
        );

        return usernameValid && passwordValid;
    }, [username, password]);

    const onLogin = useCallback(async (): Promise<void> => {
        setError("");

        const validFields = validateFields();

        if (validFields) {
            try {
                const user = await AdminAuthenticationApi.login(
                    username,
                    password
                );

                store.reset();

                localStorage.setItem("admin_user", user.id.toFixed(0));
                store.setUser(user.id);

                history.push(location.state?.referrer || "/admin");
            } catch (e) {
                localStorage.removeItem("admin_user");

                setError("app.loginFailed");
            }
        }
    }, [validateFields, username, history, location, password, store]);

    return (
        <LoggedOutLayout>
            <MirusLogo />
            <Form noValidate>
                <TextField
                    required={false}
                    value={username}
                    onChange={setUsername}
                    label={t("app.username")}
                    maxChars={50}
                    icon={"user"}
                    error={t(fieldErrors.username)}
                    onValidate={(value: string) =>
                        validate(value, validateRequired, (error) =>
                            setFieldError("username", error)
                        )
                    }
                    onFocus={() => setFieldError("username", "")}
                />
                <TextField
                    required={false}
                    value={password}
                    onChange={setPassword}
                    label={t("app.password")}
                    maxChars={255}
                    type={"password"}
                    icon={"key"}
                    error={t(fieldErrors.password)}
                    onValidate={(value: string) =>
                        validate(value, validateRequired, (error) =>
                            setFieldError("password", error)
                        )
                    }
                    onFocus={() => setFieldError("password", "")}
                />
                <VerticalSpace />
                <PrimaryButton
                    fluid
                    label={t("app.login")}
                    onAction={onLogin}
                />
                <VerticalSpace />
                {error !== "" && <Message negative>{t(error)}</Message>}
            </Form>
        </LoggedOutLayout>
    );
};
