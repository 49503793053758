import { types, SnapshotIn, Instance } from "mobx-state-tree";
import { IResult, ResultsQuality } from "erfa-common";

const Result = types
    .model({
        min: types.optional(types.number, 0),
        firstQuartil: types.optional(types.number, 0),
        median: types.optional(types.number, 0),
        thirdQuartil: types.optional(types.number, 0),
        max: types.optional(types.number, 0),
    })
    .actions((self) => ({
        setResult(result: IResult) {
            self.min = result.min;
            self.firstQuartil = result["1st_quartil"];
            self.median = result.median;
            self.thirdQuartil = result["3rd_quartil"];
            self.max = result.max;
        },
    }));

export const ResultsModel = types
    .model({
        salary: types.optional(Result, {}),
        bonus: types.optional(Result, {}),
        errorMessage: types.optional(types.string, "results.invalidFilter"),
        quality: types.maybe(types.enumeration(["low", "medium", "high"])),
    })
    .actions((self) => ({
        setErrorMessage(errorMessage: string) {
            self.errorMessage = errorMessage;
        },

        setQuality(quality: ResultsQuality) {
            self.quality = quality;
        },
    }));

export const Results = types.model({
    company: ResultsModel,
    all: ResultsModel,
});

export const resultsDefault: SnapshotIn<typeof Results> = {
    company: {},
    all: {},
};

export type IResultInstance = Instance<typeof Result>;
export type IResultsInstance = Instance<typeof Results>;
