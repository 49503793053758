import {
    IAdminCustomerUserPasswordRequest,
    IAdminCustomerUserRequest,
    IAdminCustomerUserResponse,
    IAdminCustomerUsersRequest,
} from "erfa-common";
import { requests } from "../../requests";

export const AdminCustomerUserApi = {
    all: async (customerId: number) =>
        (await requests.get(`/admin/customers/${customerId}/users`)) as Promise<
            IAdminCustomerUserResponse[]
        >,

    add: async (customerId: number, customerUser: IAdminCustomerUsersRequest) =>
        requests.post(
            `/admin/customers/${customerId}/users`,
            customerUser
        ) as Promise<IAdminCustomerUserResponse>,
    update: async (
        customerId: number,
        userId: number,
        customerUser: IAdminCustomerUserRequest
    ) =>
        requests.put(
            `/admin/customers/${customerId}/users/${userId}`,
            customerUser
        ) as Promise<IAdminCustomerUserResponse>,

    delete: async (customerId: number, userId: number) =>
        requests.delete(
            `/admin/customers/${customerId}/users/${userId}`
        ) as Promise<boolean>,
    setPassword: async (
        customerId: number,
        userId: number,
        password: IAdminCustomerUserPasswordRequest
    ) =>
        requests.put(
            `/admin/customers/${customerId}/users/${userId}/password`,
            password
        ) as Promise<{}>,
};
