import { applySnapshot, Instance, SnapshotIn, types } from "mobx-state-tree";
import { ResultsModel } from "../../erfa/models";

export const AdminResults = types
    .model({
        all: ResultsModel,
    })
    .actions((self) => ({
        resetResultsStore() {
            applySnapshot(self, { all: {} });
        },
    }));

export type IAdminResultsInstance = Instance<typeof AdminResults>;
export const adminResultsDefault: SnapshotIn<typeof AdminResults> = {
    all: {},
};
