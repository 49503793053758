import React from "react";
import {
    Route,
    Switch,
    Redirect,
    useLocation,
    useHistory,
} from "react-router-dom";
import { LoggedOutLayout } from "components";
import { observer } from "mobx-react-lite";
import { useAdminStore } from "../../Root";
import { Button, Form } from "semantic-ui-react";

export const OauthRoutes: React.FC = observer(() => {
    const { i18n, clients } = useAdminStore();

    const location = useLocation();
    const history = useHistory();
    const searchParams = new URLSearchParams(location.search);
    const clientIdString = searchParams.get("client_id");
    const clientId = clientIdString as string;

    const t = i18n.translate;
    clients.load();
    const client = clients.getById(clientId);
    const transactionId = client?.actions.transactionId;
    if (!transactionId) {
        client?.actions.authorize();
    }

    return (
        <LoggedOutLayout>
            <Switch>
                <Route exact path="/admin/oauth/authorize">
                    <Form noValidate>
                        <div>
                            <b>{client?.name}</b> is requesting full access to
                            your account.
                            <br />
                            Do you approve?
                        </div>
                        <Button
                            positive
                            onClick={async () => {
                                try {
                                    await client?.actions.allow();
                                    history.push(
                                        "/admin/oauth/authorize-success"
                                    );
                                } catch (e) {
                                    history.push(
                                        "/admin/oauth/authorize-failed"
                                    );
                                }
                            }}
                        >
                            {t("confirm.yes")}
                        </Button>
                        <Button
                            negative
                            onClick={async () => {
                                try {
                                    await client?.actions.deny();
                                    history.push("/admin");
                                } catch (e) {
                                    history.push(
                                        "/admin/oauth/authorize-failed"
                                    );
                                }
                            }}
                        >
                            {t("confirm.no")}
                        </Button>
                    </Form>
                </Route>
                <Route exact path="/admin/oauth/authorize-success">
                    <div>
                        {client?.name} is granted full access to your account.
                    </div>
                    <Button
                        positive
                        onClick={async () => {
                            history.push("/admin");
                        }}
                    >
                        {t("confirm.ok")}
                    </Button>
                </Route>
                <Route exact path="/admin/oauth/authorize-failed">
                    <div>
                        Failed!
                        <br />
                        {client?.name} couldn't get full access to your account.
                    </div>
                    <Button
                        positive
                        onClick={async () => {
                            history.push("/admin");
                        }}
                    >
                        {t("confirm.ok")}
                    </Button>
                </Route>
                <Route>
                    <Redirect to={"/admin/not_found"} />
                </Route>
            </Switch>
        </LoggedOutLayout>
    );
});
