import { supportedLanguages } from "../i18n/I18n";

const getBrowserLanguage = (): string => {
    const language: string =
        navigator["browserLanguage"] ||
        (navigator.languages && navigator.languages[0]) ||
        navigator.language;

    return language && language.substring(0, 2);
};

const getDefaultLanguage = () => {
    let browserLanguage = getBrowserLanguage();
    if (isSupportedLanguage(browserLanguage)) {
        return browserLanguage;
    }
    return "de";
};

const isSupportedLanguage = (language: string) => {
    return supportedLanguages.indexOf(language) > -1;
};

export const getLanguage = () => {
    const lsLanguage = localStorage.getItem("language");

    if (lsLanguage && isSupportedLanguage(lsLanguage)) {
        return lsLanguage;
    }

    const defaultLanguage = getDefaultLanguage();
    localStorage.setItem("language", defaultLanguage);
    return defaultLanguage;
};
