import React, { useEffect } from "react";
import { AuthenticationApi } from "erfa/api";
import { Redirect } from "react-router-dom";
import { useErfaStore } from "../../Root";

export const Logout: React.FC = () => {
    const store = useErfaStore();

    useEffect(() => {
        (async () => {
            try {
                await AuthenticationApi.logout();
            } catch (e) {
            } finally {
                store.reset();
                localStorage.removeItem("user");
            }
        })();
    }, [store]);

    return <Redirect to={"/login"} />;
};
