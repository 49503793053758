import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { AppLayout, IVerticalMenuItem, VerticalMenu } from "components";
import { observer } from "mobx-react-lite";
import { useAdminStore } from "../../Root";
import logo from "../../img/mirus_logo_transparent.png";
import hsLogo from "../../img/hotellerieSuisse_logo_transparent.png";
import { AdminFilter } from "./AdminFilter";
import { AdminCustomers } from "./AdminCustomers";
import { AdminCustomer } from "./AdminCustomer";
import { LanguageSelect } from "../../erfa_components";
import FlexView from "react-flexview";
import { AdminFilterTotal } from "./AdminFilterTotal";

export const AdminRoutes: React.FC = observer(() => {
    const { i18n } = useAdminStore();
    const t = i18n.translate;

    const topMenuItems: Array<IVerticalMenuItem> = [
        { text: t("app.customers"), link: "/admin/customers" },
        { text: t("app.filter"), link: "/admin/filter" },
        { text: t("app.filterTotal"), link: "/admin/filter-total" },
    ];

    const bottomMenuItems: IVerticalMenuItem[] = [
        { text: t("app.logout"), link: "/admin/logout", icon: "sign out" },
    ];

    return (
        <AppLayout>
            <AppLayout.Menu>
                <VerticalMenu
                    topItems={topMenuItems}
                    bottomItems={bottomMenuItems}
                    logo={{
                        src: logo,
                        link: "https://www.mirus.ch",
                        linkTarget: "blank",
                    }}
                    subLogo={{
                        src: hsLogo,
                    }}
                />
            </AppLayout.Menu>
            <AppLayout.Content>
                <AppLayout.Header>
                    <LanguageSelect admin />
                </AppLayout.Header>
                <AppLayout.Body>
                    <Switch>
                        <Route exact path="/admin/filter">
                            <AdminFilter />
                        </Route>
                        <Route exact path="/admin/filter-total">
                            <AdminFilterTotal />
                        </Route>
                        <Route exact path="/admin">
                            <Redirect to={"/admin/customers"} />
                        </Route>
                        <Route exact path="/admin/customers">
                            <AdminCustomers />
                        </Route>
                        <Route exact path="/admin/customers/:customerId(\d+)">
                            <AdminCustomer />
                        </Route>
                        <Route>
                            <Redirect to={"/admin/not_found"} />
                        </Route>
                    </Switch>
                </AppLayout.Body>
                <AppLayout.Footer>
                    <FlexView hAlignContent={"center"}>
                        {`${t("app.emailContact")}:`}&nbsp;
                        <a href={"mailto:benchmark@hotelleriesuisse.ch"}>
                            benchmark@hotelleriesuisse.ch
                        </a>
                    </FlexView>
                </AppLayout.Footer>
            </AppLayout.Content>
        </AppLayout>
    );
});
