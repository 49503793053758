import { IPublishedRecordResponse } from "erfa-common";
import { requests } from "../../requests";

export const AdminCustomerPublishedApi = {
    allByCustomer: async (customerId: number) =>
        (await requests.get(
            `/admin/customers/${customerId}/published-records`
        )) as Promise<IPublishedRecordResponse[]>,

    deleteYear: async (customerId: number, year: number) =>
        requests.delete(
            `/admin/customers/${customerId}/published-records/${year}`
        ) as Promise<boolean>,
};
