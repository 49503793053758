import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { getErfaStore, AdminProvider, ErfaProvider, getAdminStore } from "Root";
import { Router } from "react-router-dom";
import history from "erfaHistory";

ReactDOM.render(
    <Router history={history}>
        <AdminProvider value={getAdminStore()}>
            <ErfaProvider value={getErfaStore()}>
                <App />
            </ErfaProvider>
        </AdminProvider>
    </Router>,
    document.getElementById("root")
);
