import { applySnapshot, Instance, types } from "mobx-state-tree";
import { I18n } from "../../i18n/I18n";
import { FilterModel } from "./Filter";
import { Results, resultsDefault } from "./Results";
import { NewData } from "./NewData";
import { UnpublishedRecords } from "./UnpublishedRecords";
import { getLanguage } from "../../util/language";
import { Positions } from "./Positions";
import { AllPositions } from "./AllPositions";
import { WeeklyHours } from "./WeeklyHours";
import { FilterTotalModel } from "./FilterTotal";
import { ResultsTotalModel } from "./ResultsTotal";

export const ErfaModel = types
    .model({
        i18n: types.optional(I18n, {
            language: getLanguage(),
        }),
        filter: types.optional(FilterModel, {}),
        filterTotal: types.optional(FilterTotalModel, {}),
        results: types.optional(Results, resultsDefault),
        resultsTotal: types.optional(ResultsTotalModel, resultsDefault),
        newData: types.optional(NewData, {}),
        unpublishedRecords: types.optional(UnpublishedRecords, {}),
        positions: types.optional(Positions, {}),
        allPositions: types.optional(AllPositions, {}),
        weeklyHours: types.optional(WeeklyHours, {}),
    })
    .actions((self) => ({
        reset() {
            applySnapshot(self, { i18n: { ...self.i18n } });
        },
    }));

export type IErfaModel = Instance<typeof ErfaModel>;
