import { getRoot, Instance, types } from "mobx-state-tree";
import { Language, SupportedLanguage } from "erfa-common";
import { createRestJoinCollectionModel } from "../../Framework/models/CollectionModel";
import {
    createRecordModel,
    createRestRecordModel,
    ModelInterface,
} from "../../Framework/models/RecordModel";
import { AdminPositionApi } from "../api";
import { IErfaModel } from "./Erfa";

export const Position = createRecordModel(
    "id",
    "number",
    { description: types.string },
    {}
);

export const PositionGroup = createRestRecordModel(
    "id",
    "number",
    { description: types.string, positions: types.array(Position) },
    {},
    {
        errorMap: new Map(),

        update: () => {
            throw new Error("unsupported update");
        },
    }
);

const createPositionsModel = (language: Language) =>
    createRestJoinCollectionModel(PositionGroup, "id", "number", {
        errorMap: new Map(),
        resource: "position",

        all: async () => {
            return await AdminPositionApi.all(language);
        },
        create: () => {
            throw new Error("unsupported create");
        },
        remove: () => {
            throw new Error("unsupported remove");
        },
    });

const PositionsDe = createPositionsModel(SupportedLanguage.DEUTSCH);

const PositionsFr = createPositionsModel(SupportedLanguage.FRENCH);

const PositionsIt = createPositionsModel(SupportedLanguage.ITALIAN);

export const Positions = types
    .model({
        de: types.optional(PositionsDe, {}),
        fr: types.optional(PositionsFr, {}),
        it: types.optional(PositionsIt, {}),
    })
    .actions((self) => ({
        load: () => {
            const language = (getRoot(self) as IErfaModel).i18n
                .language as Language;

            return self[language].load();
        },
        findPositionById(positionId: number): IPositionInstance | undefined {
            const language = (getRoot(self) as IErfaModel).i18n
                .language as Language;

            let foundPosition: IPositionInstance | undefined = undefined;
            self[language].items.findIndex(({ positions }) => {
                return !!positions.some((position) => {
                    if (position.id === positionId) {
                        foundPosition = position;
                        return true;
                    }
                    return false;
                });
            });
            return foundPosition;
        },
    }))
    .views((self) => ({
        get positionList() {
            const language = (getRoot(self) as IErfaModel).i18n
                .language as Language;

            return self[language].items.map(({ description, positions }) => ({
                header: description,
                items: positions.map(({ description, id }) => ({
                    value: id,
                    display: description,
                })),
            }));
        },
    }));

export type IPositionGroup = ModelInterface<typeof PositionGroup>;
export type IPositionInstance = Instance<typeof Position>;
