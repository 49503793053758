import React, { useState, useEffect } from "react";
import { Form, CheckboxProps, Checkbox } from "semantic-ui-react";

interface IProps {
    value: boolean;
    onChange?: (value: boolean) => void;
    disabled?: boolean;
    required?: boolean;
    label: string;
}

export const CheckboxField: React.FC<IProps> = ({
    value: propsValue,
    onChange,
    label,
    disabled,
    required,
}) => {
    const [value, setValue] = useState<boolean>(propsValue);

    useEffect(() => {
        setValue(propsValue);
    }, [propsValue]);

    return (
        <Form.Field required={required} disabled={disabled}>
            <label> {label}</label>
            <Checkbox
                checked={value}
                onChange={(_e: any, data: CheckboxProps) => {
                    setValue(data.checked!);
                    onChange?.(data.checked!);
                }}
            />
        </Form.Field>
    );
};
