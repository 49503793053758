import React from "react";
import { SimpleModal, SimpleModalButton } from "components";

const buttonsDeleteModal = (
    primary: string,
    secondary: string
): { [key: string]: SimpleModalButton } => ({
    primary: {
        text: primary,
        type: "primary",
        id: "primary",
    },
    secondary: {
        text: secondary,
        type: "secondary",
        id: "secondary",
    },
});

interface IProps {
    onButtonClick: (id: string) => void;
    translate: (key: string) => string;
}

export const ConfirmDeleteModal: React.FC<IProps> = ({
    onButtonClick,
    translate: t,
}) => {
    const buttons = buttonsDeleteModal(t("app.yes"), t("app.no"));
    return (
        <SimpleModal
            buttons={[buttons.primary, buttons.secondary]}
            text={t("app.confirmDelete")}
            onButtonClick={onButtonClick}
        />
    );
};
