import React, { ReactText, useEffect, useState } from "react";

import FlexView from "react-flexview";
import {
    Dropdown as UIDropdown,
    Button,
    Form,
    Message,
} from "semantic-ui-react";
import { extractComponent } from "./utils/extractComponent";
import { SemanticICONS } from "semantic-ui-react/dist/commonjs/generic";
import {
    TextField,
    NumberField,
    Dropdown,
    DateInput,
    ButtonSelect,
    DropdownValue,
    ResponsiveButton,
    VerticalSpace,
    CollapsibleSegment,
    DropdownOption,
    IDetailBaseButton,
    HorizontalSpace,
    ButtonSelectValue,
} from "components";
import { Instance } from "mobx-state-tree";
import {
    RecordType,
    CreateRecordType,
    IRestRecordModel,
} from "./models/RecordModel";

import _ from "lodash";
import { ConfirmDeleteModal } from "../erfa_components/ConfirmDeleteModal";
import { FailedDeleteModal } from "../erfa_components/FailedDeleteModal";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { IRestCollectionModel } from "./models/CollectionModel";
import { StatusCodes } from "http-status-codes";
import { forbiddenError } from "./api";
import { useErfaStore } from "../Root";
import { StarCategoryDropdown } from "../erfa_components/StarCategoryDropdown";
import { CheckboxField } from "./CheckboxField";

export interface IDetailProps<
    K extends string extends K ? never : string,
    KT extends "string" | "number",
    T extends IRestRecordModel<K, any, any, any>
> {
    headerDataprovider?: keyof RecordType<T> | ((model: Instance<T>) => string);
    title: string;
    model?: Instance<T>;
    collection?: Instance<IRestCollectionModel<K, KT, T>>;
    children?: React.ReactNode;
    canDelete?: boolean;
    canEdit?: boolean;
    canCreate?: boolean;
    modal?: boolean;
    onSave?: () => void;
    onCancel?: () => void;
    onClose?: () => void;
    collapsible?: boolean;
    translate: (key: string) => string;
    menuInHeader?: boolean;
    displayButtonsAfterChange?: boolean;
    onValidate?: (fieldValues: Instance<T>) => string[];
}

type DetailRecordModel<T> = T extends IDetailProps<infer _K, any, infer T2>
    ? T2
    : never;
type DetailRecordModelKey<T> = T extends IDetailProps<infer K, any, any>
    ? K
    : never;

export interface ITextFieldProps<
    K extends string extends K ? never : string,
    T extends IRestRecordModel<K, any, any, any>
> {
    dataprovider: keyof RecordType<T>;
    label: string;
    required?: boolean;
    disabled?: boolean | ((model?: Instance<T>) => boolean);
    maxChars?: number;
    type?: "text" | "password";
    icon?: SemanticICONS;
    onValidate?: (value: string) => string;
}

export interface INumberFieldProps<
    K extends string extends K ? never : string,
    T extends IRestRecordModel<K, any, any, any>
> {
    dataprovider: keyof RecordType<T>;
    label: string;
    required?: boolean;
    disabled?: boolean | ((model?: Instance<T>) => boolean);
    minValue?: number;
    maxValue?: number;
    decimalCount?: number;
    onValidate?: (value: number | undefined) => string;
}

export interface IDropdownProps<
    K extends string extends K ? never : string,
    T extends IRestRecordModel<K, any, any, any>
> {
    dataprovider: keyof RecordType<T>;
    label: string;
    required?: boolean;
    disabled?: boolean | ((model?: Instance<T>) => boolean);
    options: DropdownOption[];
    fallbackOptions?: DropdownOption[];
    onValidate?: (value: string) => string;
}

export interface IStarCategoryDropdownProps<
    K extends string extends K ? never : string,
    T extends IRestRecordModel<K, any, any, any>
> {
    dataprovider: keyof RecordType<T>;
    label: string;
    required?: boolean;
    disabled?: boolean | ((model?: Instance<T>) => boolean);
    onValidate?: (value: string) => string;
}

export interface IDateFieldProps<
    K extends string extends K ? never : string,
    T extends IRestRecordModel<K, any, any, any>
> {
    dataprovider: keyof RecordType<T>;
    label: string;
    required?: boolean;
    disabled?: boolean | ((model?: Instance<T>) => boolean);
    minDate?: moment.Moment;
    maxDate?: moment.Moment;
    onValidate?: (value: string) => string;
}

export interface ICheckboxProps<
    K extends string extends K ? never : string,
    T extends IRestRecordModel<K, any, any, any>
> {
    dataprovider: keyof RecordType<T>;
    label: string;
    required?: boolean;
    disabled?: boolean | ((model?: Instance<T>) => boolean);
}

interface IBaseButtonFieldProps {
    label: string;
    onAction: () => void;
    fluid?: boolean;
    disabled?: boolean;
}

interface IPrimaryButtonFieldProps extends IBaseButtonFieldProps {
    primary: true;
    secondary?: false;
    negative?: false;
}

interface ISecondaryButtonFieldProps extends IBaseButtonFieldProps {
    primary?: false;
    secondary: true;
    negative?: false;
}

interface INegativeButtonFieldProps extends IBaseButtonFieldProps {
    primary?: false;
    secondary?: false;
    negative: true;
}

export type IButtonFieldProps =
    | IPrimaryButtonFieldProps
    | ISecondaryButtonFieldProps
    | INegativeButtonFieldProps
    | IBaseButtonFieldProps;

const DetailTextField: <
    K extends string extends K ? never : string,
    T extends IRestRecordModel<K, any, any, any>
>(
    props: ITextFieldProps<K, T>
) => React.ReactElement | null = (props) => {
    throw new Error("Abstract component rendered!");
};

const DetailNumberField: <
    K extends string extends K ? never : string,
    T extends IRestRecordModel<K, any, any, any>
>(
    props: INumberFieldProps<K, T>
) => React.ReactElement | null = (props) => {
    throw new Error("Abstract component rendered!");
};

const DetailDropdown: <
    K extends string extends K ? never : string,
    T extends IRestRecordModel<K, any, any, any>
>(
    props: IDropdownProps<K, T>
) => React.ReactElement | null = (props) => {
    throw new Error("Abstract component rendered!");
};

const DetailStarCategoryDropdown: <
    K extends string extends K ? never : string,
    T extends IRestRecordModel<K, any, any, any>
>(
    props: IStarCategoryDropdownProps<K, T>
) => React.ReactElement | null = (_props) => {
    throw new Error("Abstract component rendered!");
};

const DetailDateField: <
    K extends string extends K ? never : string,
    T extends IRestRecordModel<K, any, any, any>
>(
    props: IDateFieldProps<K, T>
) => React.ReactElement | null = (_props) => {
    throw new Error("Abstract component rendered!");
};

const DetailButtonSelect: <
    K extends string extends K ? never : string,
    T extends IRestRecordModel<K, any, any, any>
>(
    props: IDropdownProps<K, T>
) => React.ReactElement | null = (_props) => {
    throw new Error("Abstract component rendered!");
};

const DetailButtonField: (
    props: IButtonFieldProps
) => React.ReactElement | null = (_props) => {
    throw new Error("Abstract component rendered!");
};

const DetailCheckbox: <
    K extends string extends K ? never : string,
    T extends IRestRecordModel<K, any, any, any>
>(
    props: ICheckboxProps<K, T>
) => React.ReactElement | null = (_props) => {
    throw new Error("Abstract component rendered!");
};

export type OpenModal =
    | "none"
    | "delete_confirm"
    | "delete_failed"
    | "delete_permission";

export type IDetailFC<
    K extends string extends K ? never : string,
    KT extends "string" | "number",
    T extends IRestRecordModel<K, any, any, any>
> = React.FC<IDetailProps<K, KT, T>> & {
    TextField: React.FC<ITextFieldProps<K, T>>;
    NumberField: React.FC<INumberFieldProps<K, T>>;
    Dropdown: React.FC<IDropdownProps<K, T>>;
    StarCategoryDropdown: React.FC<IStarCategoryDropdownProps<K, T>>;
    DateField: React.FC<IDateFieldProps<K, T>>;
    ButtonSelect: React.FC<IDropdownProps<K, T>>;
    ButtonField: React.FC<IButtonFieldProps>;
    Checkbox: React.FC<ICheckboxProps<K, T>>;
};

const Detail: <
    K extends string extends K ? never : string,
    KT extends "string" | "number",
    T extends IRestRecordModel<K, any, any, any>
>(
    props: IDetailProps<K, KT, T>
) => React.ReactElement | null = observer((props) => {
    const {
        headerDataprovider,
        title,
        model,
        canDelete,
        canEdit,
        canCreate,
        children,
        onSave,
        onCancel,
        onClose,
        modal,
        collection,
        collapsible,
        translate: t,
        menuInHeader,
        displayButtonsAfterChange,
        onValidate,
    } = props;

    const allDisabled =
        (model && !canEdit) ||
        (collection && !canCreate) ||
        (!collection && !model);

    type T = DetailRecordModel<typeof props>;
    type K = DetailRecordModelKey<typeof props>;

    const [modalOpen, setModalOpen] = useState<OpenModal>("none");
    const [formErrors, setFormErrors] = useState<string[]>([]);
    const childArray = React.Children.toArray(children) as React.ReactElement[];

    const dataproviders: (keyof Instance<T>)[] = [];
    const validateFuncs: {
        [P in keyof RecordType<T>]?: (value: any) => string;
    } = {};

    function isFunction(
        disabled: boolean | ((model?: Instance<T> | undefined) => boolean)
    ): disabled is (model?: Instance<T>) => boolean {
        return disabled instanceof Function;
    }

    const extractedChildren = childArray.map((child) => {
        const extracted = extractComponent(child, {
            text: DetailTextField,
            number: DetailNumberField,
            dropdown: DetailDropdown,
            starCategoryDropdown: DetailStarCategoryDropdown,
            date: DetailDateField,
            buttonselect: DetailButtonSelect,
            buttonField: DetailButtonField,
            checkbox: DetailCheckbox,
        });

        if (extracted.text) {
            const textField = extracted.text as React.ReactElement<
                ITextFieldProps<K, T>
            >;
            dataproviders.push(textField.props.dataprovider);
            if (textField.props.onValidate) {
                validateFuncs[textField.props.dataprovider] =
                    textField.props.onValidate;
            }
        }
        if (extracted.number) {
            const numberField = extracted.number as React.ReactElement<
                ITextFieldProps<K, T>
            >;
            dataproviders.push(numberField.props.dataprovider);
            if (numberField.props.onValidate) {
                validateFuncs[numberField.props.dataprovider] =
                    numberField.props.onValidate;
            }
        }
        if (extracted.dropdown) {
            const dropdown = extracted.dropdown as React.ReactElement<
                IDropdownProps<K, T>
            >;
            dataproviders.push(dropdown.props.dataprovider);
            if (dropdown.props.onValidate) {
                validateFuncs[dropdown.props.dataprovider] =
                    dropdown.props.onValidate;
            }
        }
        if (extracted.starCategoryDropdown) {
            const starCategoryDropdown = extracted.starCategoryDropdown as React.ReactElement<
                IDropdownProps<K, T>
            >;
            dataproviders.push(starCategoryDropdown.props.dataprovider);
            if (starCategoryDropdown.props.onValidate) {
                validateFuncs[starCategoryDropdown.props.dataprovider] =
                    starCategoryDropdown.props.onValidate;
            }
        }
        if (extracted.date) {
            const date = extracted.date as React.ReactElement<
                IDropdownProps<K, T>
            >;
            dataproviders.push(date.props.dataprovider);
            if (date.props.onValidate) {
                validateFuncs[date.props.dataprovider] = date.props.onValidate;
            }
        }
        if (extracted.buttonselect) {
            const buttonselect = extracted.buttonselect as React.ReactElement<
                IDropdownProps<K, T>
            >;
            dataproviders.push(buttonselect.props.dataprovider);
            if (buttonselect.props.onValidate) {
                validateFuncs[buttonselect.props.dataprovider] =
                    buttonselect.props.onValidate;
            }
        }

        if (extracted.checkbox) {
            const checkbox = extracted.checkbox as React.ReactElement<
                ICheckboxProps<K, T>
            >;
            dataproviders.push(checkbox.props.dataprovider);
        }
        return extracted;
    });

    const initialFieldErrors = _.chain(
        dataproviders.map((v) => ({ name: v, value: "" }))
    )
        .keyBy("name")
        .mapValues("value")
        .value() as { [P in keyof Instance<T>]: string };

    const initialFieldValues = _.chain(
        dataproviders.map((v) => {
            return {
                name: v,
                value: model ? model[v] : null,
            };
        })
    )
        .keyBy("name")
        .mapValues("value")
        .value() as Instance<T>;

    const [fieldValues, setFieldValues] = useState<Instance<T>>(
        initialFieldValues
    );
    const [fieldErrors, setFieldErrors] = useState<
        { [P in keyof Instance<T>]: string }
    >(initialFieldErrors);

    useEffect(() => {
        setFieldErrors(initialFieldErrors);
        collection?.clearErrors();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [collection]);

    useEffect(() => {
        const initialValues = _.chain(
            dataproviders.map((v) => ({
                name: v,
                value: model ? model[v] : null,
            }))
        )
            .keyBy("name")
            .mapValues("value")
            .value() as Instance<T>;

        const newErrors: Record<string, string> = {};
        for (const provider in validateFuncs) {
            newErrors[provider] = model
                ? validateFuncs[provider]!(initialValues[provider])
                : "";
        }
        const mergedErrors = {
            ...fieldErrors,
            ...newErrors,
        };
        setFieldValues(initialValues);
        setFieldErrors(mergedErrors);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [model]);

    const mappedChildren = extractedChildren.map((extracted, index) => {
        if (extracted.text) {
            const textField = extracted.text as React.ReactElement<
                ITextFieldProps<K, T>
            >;
            const {
                dataprovider,
                onValidate,
                disabled,
                ...textFieldProps
            } = textField.props;
            return (
                <TextField
                    key={`${index}`}
                    value={(fieldValues?.[dataprovider] as string) || ""}
                    onChange={(value: string) => {
                        setFieldValues({
                            ...fieldValues,
                            [dataprovider]: value,
                        });
                    }}
                    disabled={
                        allDisabled ||
                        (disabled && isFunction(disabled)
                            ? disabled(model)
                            : disabled)
                    }
                    error={(fieldErrors?.[dataprovider] as string) || ""}
                    onValidate={
                        onValidate
                            ? (value: string) =>
                                  setFieldErrors({
                                      ...fieldErrors,
                                      [dataprovider]: onValidate(value),
                                  })
                            : undefined
                    }
                    {...textFieldProps}
                />
            );
        } else if (extracted.number) {
            const numberField = extracted.number as React.ReactElement<
                INumberFieldProps<K, T>
            >;
            const {
                dataprovider,
                onValidate,
                disabled,
                ...numberFieldProps
            } = numberField.props;
            return (
                <NumberField
                    key={`${index}`}
                    value={fieldValues?.[dataprovider] as number | undefined}
                    onChange={(value: number | undefined) => {
                        setFieldValues({
                            ...fieldValues,
                            [dataprovider]: value,
                        });
                    }}
                    disabled={
                        allDisabled ||
                        (disabled && isFunction(disabled)
                            ? disabled(model)
                            : disabled)
                    }
                    error={(fieldErrors?.[dataprovider] as string) || ""}
                    onValidate={
                        onValidate
                            ? (value: number | undefined) =>
                                  setFieldErrors({
                                      ...fieldErrors,
                                      [dataprovider]: onValidate(value),
                                  })
                            : undefined
                    }
                    {...numberFieldProps}
                />
            );
        } else if (extracted.dropdown) {
            const dropdown = extracted.dropdown as React.ReactElement<
                IDropdownProps<K, T>
            >;
            const {
                dataprovider,
                onValidate,
                options,
                fallbackOptions,
                disabled,
                ...dropdownProps
            } = dropdown.props;
            const value = (fieldValues?.[dataprovider] as string) || "";
            let trueOptions = options;
            if (
                value &&
                fallbackOptions &&
                !options.find((option) => option.value === value)
            ) {
                const fallbackOption = fallbackOptions.find(
                    (option) => option.value === value
                );
                if (fallbackOption) {
                    trueOptions = [...options, fallbackOption];
                }
            }
            return (
                <Dropdown
                    key={`${index}`}
                    value={fieldValues?.[dataprovider]}
                    onChange={(value: DropdownValue) => {
                        setFieldValues({
                            ...fieldValues,
                            [dataprovider]: value,
                        });
                    }}
                    disabled={
                        allDisabled ||
                        (disabled && isFunction(disabled)
                            ? disabled(model)
                            : disabled)
                    }
                    options={trueOptions}
                    error={(fieldErrors?.[dataprovider] as string) || ""}
                    onValidate={
                        onValidate
                            ? (value: DropdownValue | undefined) =>
                                  setFieldErrors({
                                      ...fieldErrors,
                                      [dataprovider]: onValidate(
                                          value as string
                                      ),
                                  })
                            : undefined
                    }
                    onFocus={() => {
                        setFieldErrors({
                            ...fieldErrors,
                            [dataprovider]: "",
                        });
                    }}
                    {...dropdownProps}
                />
            );
        } else if (extracted.starCategoryDropdown) {
            const starCategoryDropdown = extracted.starCategoryDropdown as React.ReactElement<
                IStarCategoryDropdownProps<K, T>
            >;
            const {
                dataprovider,
                onValidate,
                disabled,
                ...starCategoryDropdownProps
            } = starCategoryDropdown.props;

            return (
                <StarCategoryDropdown
                    translate={t}
                    key={`${index}`}
                    value={fieldValues?.[dataprovider]}
                    onChange={(value: DropdownValue) => {
                        setFieldValues({
                            ...fieldValues,
                            [dataprovider]: value,
                        });
                    }}
                    disabled={
                        allDisabled ||
                        (disabled && isFunction(disabled)
                            ? disabled(model)
                            : disabled)
                    }
                    error={(fieldErrors?.[dataprovider] as string) || ""}
                    onValidate={
                        onValidate
                            ? (value: DropdownValue | undefined) =>
                                  setFieldErrors({
                                      ...fieldErrors,
                                      [dataprovider]: onValidate(
                                          value as string
                                      ),
                                  })
                            : undefined
                    }
                    onFocus={() => {
                        setFieldErrors({
                            ...fieldErrors,
                            [dataprovider]: "",
                        });
                    }}
                    {...starCategoryDropdownProps}
                />
            );
        } else if (extracted.date) {
            const date = extracted.date as React.ReactElement<
                IDateFieldProps<K, T>
            >;
            const {
                dataprovider,
                onValidate,
                disabled,
                ...dateProps
            } = date.props;
            return (
                <DateInput
                    key={`${index}`}
                    value={
                        fieldValues?.[dataprovider]
                            ? moment(fieldValues?.[dataprovider] as Date)
                            : undefined
                    }
                    onChange={(value: moment.Moment | undefined) => {
                        setFieldValues({
                            ...fieldValues,
                            [dataprovider]: value?.toDate(),
                        });
                    }}
                    disabled={
                        allDisabled ||
                        (disabled && isFunction(disabled)
                            ? disabled(model)
                            : disabled)
                    }
                    error={(fieldErrors?.[dataprovider] as string) || ""}
                    onValidate={
                        onValidate
                            ? (value: string) =>
                                  setFieldErrors({
                                      ...fieldErrors,
                                      [dataprovider]: onValidate(
                                          value as string
                                      ),
                                  })
                            : undefined
                    }
                    translateDateFormat={
                        useErfaStore().i18n.translateDateFormat
                    }
                    {...dateProps}
                />
            );
        } else if (extracted.buttonselect) {
            const date = extracted.buttonselect as React.ReactElement<
                IDropdownProps<K, T>
            >;
            const {
                dataprovider,
                onValidate,
                disabled,
                ...dateProps
            } = date.props;
            return (
                <ButtonSelect
                    key={`${index}`}
                    value={fieldValues?.[dataprovider] as ButtonSelectValue}
                    onChange={(value: ReactText) => {
                        setFieldValues({
                            ...fieldValues,
                            [dataprovider]: value,
                        });
                    }}
                    disabled={
                        allDisabled ||
                        (disabled && isFunction(disabled)
                            ? disabled(model)
                            : disabled)
                    }
                    error={(fieldErrors?.[dataprovider] as string) || ""}
                    onValidate={
                        onValidate
                            ? (value: ReactText) =>
                                  setFieldErrors({
                                      ...fieldErrors,
                                      [dataprovider]: onValidate(
                                          value as string
                                      ),
                                  })
                            : undefined
                    }
                    {...dateProps}
                />
            );
        } else if (extracted.checkbox) {
            const checkbox = extracted.checkbox as React.ReactElement<
                ICheckboxProps<K, T>
            >;
            const { dataprovider, disabled, ...checkboxProps } = checkbox.props;
            return (
                <CheckboxField
                    key={`${index}`}
                    value={fieldValues?.[dataprovider] as boolean}
                    onChange={(value: boolean) => {
                        setFieldValues({
                            ...fieldValues,
                            [dataprovider]: value,
                        });
                    }}
                    disabled={
                        allDisabled ||
                        (disabled && isFunction(disabled)
                            ? disabled(model)
                            : disabled)
                    }
                    {...checkboxProps}
                />
            );
        } else if (extracted.buttonField) {
            const buttonField = extracted.buttonField as React.ReactElement<IButtonFieldProps>;

            return <ResponsiveButton key={`${index}`} {...buttonField.props} />;
        } else {
            throw new Error("unsupported field type");
        }
    });

    const isNewRecordWithoutButtonsAndChange =
        collection &&
        canCreate &&
        displayButtonsAfterChange &&
        dataproviders.some((field) => !!fieldValues[field]);

    const isNewRecordWithButtons =
        collection && canCreate && !displayButtonsAfterChange;

    const isRecordWithChange =
        model &&
        canEdit &&
        dataproviders.some((field) =>
            model ? model[field] !== fieldValues[field] : false
        );

    const edited =
        isNewRecordWithoutButtonsAndChange ||
        isNewRecordWithButtons ||
        isRecordWithChange;

    const buttons: IDetailBaseButton[] = [];

    if (edited) {
        buttons.push(
            {
                label: t("app.save"),
                buttonType: "primary",
                onClick: async () => {
                    const newErrors: Record<string, string> = {};
                    for (const provider in validateFuncs) {
                        newErrors[provider] = validateFuncs[provider]!(
                            fieldValues[provider]
                        );
                    }
                    const mergedErrors = {
                        ...fieldErrors,
                        ...newErrors,
                    } as {
                        [P in keyof Instance<T>]: string;
                    };
                    setFieldErrors(mergedErrors);
                    if (_.every(mergedErrors, (err) => err === "")) {
                        const validationError = onValidate?.(fieldValues);
                        if (validationError?.length) {
                            setFormErrors(validationError);
                            return;
                        }

                        if (canEdit && model) {
                            const success = await model.set(
                                (fieldValues as unknown) as CreateRecordType<T>
                            );
                            if (success) {
                                onSave?.();
                            }
                            return;
                        }
                        if (!model && canCreate && collection) {
                            const success = await collection.add(
                                (fieldValues as unknown) as CreateRecordType<T>
                            );
                            if (success) {
                                setFormErrors([]);
                                onSave?.();
                            }
                            return;
                        }
                    }
                },
            },
            {
                label: t("app.cancel"),
                buttonType: "secondary",
                onClick: async () => {
                    setFieldValues(initialFieldValues);
                    setFieldErrors(initialFieldErrors);
                    model?.clearErrors();
                    setFormErrors([]);

                    if (!model && collection) {
                        collection.clearErrors();
                    }

                    onCancel?.();
                },
            }
        );
    }

    if (modal && model) {
        if (canDelete) {
            buttons.push({
                label: t("app.delete"),
                buttonType: "negative",
                onClick: () => {
                    setModalOpen("delete_confirm");
                },
            });
        }
        buttons.push({
            label: t("app.close"),
            buttonType: "default",
            onClick: () => {
                model.clearErrors();
                onClose!();
            },
        });
    }

    return (
        <>
            {modalOpen === "delete_confirm" && (
                <ConfirmDeleteModal
                    translate={t}
                    onButtonClick={async (button: string) => {
                        if (button === "primary") {
                            try {
                                setModalOpen("none");
                                await model!.remove();
                                if (modal) {
                                    onClose?.();
                                }
                            } catch (e) {
                                if (
                                    e.response?.status === StatusCodes.FORBIDDEN
                                ) {
                                    setModalOpen("delete_permission");
                                } else {
                                    setModalOpen("delete_failed");
                                }
                            }
                        } else {
                            setModalOpen("none");
                        }
                    }}
                />
            )}
            {modalOpen === "delete_failed" && (
                <FailedDeleteModal
                    message={"app.deleteFailed"}
                    onButtonClick={() => setModalOpen("none")}
                    translate={t}
                />
            )}
            {modalOpen === "delete_permission" && (
                <FailedDeleteModal
                    message={forbiddenError}
                    onButtonClick={() => setModalOpen("none")}
                    translate={t}
                />
            )}
            <FlexView column grow>
                {!modal && (
                    <>
                        <FlexView style={{ marginTop: "-3.57em" }}>
                            <FlexView hAlignContent={"left"}>
                                <h1>
                                    {model &&
                                        headerDataprovider &&
                                        (headerDataprovider instanceof Function
                                            ? headerDataprovider(model)
                                            : model[headerDataprovider])}
                                </h1>
                            </FlexView>
                            <FlexView
                                hAlignContent={"right"}
                                marginLeft={"auto"}
                            >
                                {canDelete && model && !menuInHeader && (
                                    <UIDropdown
                                        trigger={
                                            <Button icon="ellipsis vertical" />
                                        }
                                        icon={null}
                                        direction="left"
                                    >
                                        <UIDropdown.Menu>
                                            <UIDropdown.Item
                                                onClick={() =>
                                                    setModalOpen(
                                                        "delete_confirm"
                                                    )
                                                }
                                            >
                                                {t("app.delete")}
                                            </UIDropdown.Item>
                                        </UIDropdown.Menu>
                                    </UIDropdown>
                                )}
                            </FlexView>
                        </FlexView>
                        <VerticalSpace small />
                    </>
                )}
                <CollapsibleSegment
                    collapsible={collapsible !== false}
                    open
                    title={title}
                    children={<Form noValidate>{mappedChildren}</Form>}
                    menuInHeader={
                        canDelete && model && menuInHeader
                            ? [
                                  {
                                      onItemClick: () => {
                                          setModalOpen("delete_confirm");
                                      },
                                      title: t("app.delete"),
                                  },
                              ]
                            : undefined
                    }
                    footer={
                        buttons.length && (
                            <>
                                {buttons.map((button, index) => (
                                    <React.Fragment key={`button${index}`}>
                                        <ResponsiveButton
                                            fluid={false}
                                            label={button.label}
                                            {...{
                                                [button.buttonType]: true,
                                            }}
                                            onAction={button.onClick}
                                        />
                                        {index < buttons.length - 1 && (
                                            <HorizontalSpace />
                                        )}
                                    </React.Fragment>
                                ))}
                                {model?.errors?.length > 0 && (
                                    <Message negative>
                                        <Message.Header>
                                            {t("app.error")}
                                        </Message.Header>
                                        {model!.errors.map((error: string) => (
                                            <Message.Item key={error}>
                                                {t(error)}
                                            </Message.Item>
                                        ))}
                                    </Message>
                                )}
                                {(collection?.errors?.length ?? 0) > 0 && (
                                    <Message negative>
                                        <Message.Header>
                                            {t("app.error")}
                                        </Message.Header>
                                        {collection!.errors.map(
                                            (error: string) => (
                                                <Message.Item key={error}>
                                                    {t(error)}
                                                </Message.Item>
                                            )
                                        )}
                                    </Message>
                                )}
                                {(formErrors.length ?? 0) > 0 && (
                                    <Message negative>
                                        <Message.Header>
                                            {t("app.error")}
                                        </Message.Header>
                                        {formErrors.map((error: string) => (
                                            <Message.Item key={error}>
                                                {t(error)}
                                            </Message.Item>
                                        ))}
                                    </Message>
                                )}
                            </>
                        )
                    }
                />
            </FlexView>
        </>
    );
});

export function createDetail<
    K extends string extends K ? never : string,
    KT extends "string" | "number",
    T extends IRestRecordModel<K, any, any, any>
>(_model: T) {
    const TypedDetail = Detail as IDetailFC<K, KT, T>;
    TypedDetail.TextField = DetailTextField;
    TypedDetail.NumberField = DetailNumberField;
    TypedDetail.Dropdown = DetailDropdown;
    TypedDetail.StarCategoryDropdown = DetailStarCategoryDropdown;
    TypedDetail.DateField = DetailDateField;
    TypedDetail.ButtonSelect = DetailButtonSelect;
    TypedDetail.ButtonField = DetailButtonField;
    TypedDetail.Checkbox = DetailCheckbox;
    return TypedDetail;
}
