import { Instance } from "mobx-state-tree";
import React, { useState } from "react";
import { Modal } from "semantic-ui-react";
import {
    CollapsibleSegment,
    ResponsiveButton,
    VerticalSpace,
    HorizontalSpace,
    Table,
    ITableProps,
} from "components";
import { createDetail, IDetailProps } from "./Detail";
import { IRestJoinCollectionModel } from "./models/CollectionModel";
import { IRestRecordModel } from "./models/RecordModel";
import { extractComponent } from "./utils/extractComponent";

type DetailRecordModelKey<T> = T extends ITableSegmentProps<infer K, any>
    ? K
    : never;

interface ITableSegmentProps<
    K extends string extends K ? never : string,
    T extends IRestJoinCollectionModel<K, any, any>
> {
    title: string;
    canCreate?: boolean;
    canEdit?: boolean;
    canDelete?: boolean;
    children: React.ReactNode;
    collection: Instance<T>;
    primaryColumn: string;
    edited?: boolean;
    onSave?: () => void;
    onCancel?: () => void;
    translate: (key: string) => string;
}

export const TableSegment: <
    K extends string extends K ? never : string,
    T extends IRestJoinCollectionModel<K, any, any>
>(
    props: ITableSegmentProps<K, T>
) => React.ReactElement | null = (props) => {
    type K = DetailRecordModelKey<typeof props>;

    const {
        title,
        children,
        primaryColumn,
        canCreate,
        canEdit,
        canDelete,
        collection,
        edited,
        onSave,
        onCancel,
        translate: t,
    } = props;
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    const [detailId, setDetailId] = useState<number | undefined>(undefined);

    let modalCreate: React.ReactElement | null = null;
    let modalEdit: React.ReactElement | null = null;

    let tableComponent: React.ReactElement<ITableProps> | null = null;
    let detailComponent: React.ReactElement<
        IDetailProps<K, any, IRestRecordModel<K, any, any, any>>
    > | null = null;

    const Detail = createDetail({} as IRestRecordModel<K, any, any, any>);

    const childArray = React.Children.toArray(children) as React.ReactElement[];
    childArray.forEach((child) => {
        const extractedComponents = extractComponent(
            child,
            {
                detail: createDetail({} as IRestRecordModel<K, any, any, any>),
                table: Table,
            },
            1
        );
        if (extractedComponents.detail) {
            detailComponent = extractedComponents.detail as React.ReactElement<
                IDetailProps<K, any, IRestRecordModel<K, any, any, any>>
            >;
        }
        if (extractedComponents.table) {
            tableComponent = extractedComponents.table as React.ReactElement<
                ITableProps
            >;
        }
    });

    if (!tableComponent) {
        throw new Error("Missing table component");
    }

    let table = tableComponent as React.ReactElement<ITableProps>;

    if (detailComponent) {
        table = (
            <Table
                {...table.props}
                onRowClick={(row: object) => {
                    setDetailId(row[primaryColumn]);
                }}
                noRowsMessage={t("app.tableNoRows")}
            />
        );
        const detail = detailComponent as React.ReactElement<
            IDetailProps<K, any, IRestRecordModel<K, any, any, any>>
        >;

        if (detailId) {
            modalEdit = (
                <Modal
                    data-testid="modal"
                    basic={true}
                    closeOnDimmerClick={false}
                    closeOnEscape={false}
                    open={detailId !== undefined}
                >
                    <Modal.Content>
                        <Detail
                            {...detail.props}
                            onCancel={() => {
                                detail.props.onCancel?.();
                                setDetailId(undefined);
                            }}
                            onSave={() => {
                                detail.props.onSave?.();
                                setDetailId(undefined);
                            }}
                            onClose={() => {
                                detail.props.onClose?.();
                                setDetailId(undefined);
                            }}
                            collapsible={false}
                            canEdit={canEdit}
                            canDelete={canDelete}
                            modal
                            model={collection.getById(detailId)}
                        />
                    </Modal.Content>
                </Modal>
            );
        }

        modalCreate =
            canCreate !== false ? (
                <Modal
                    data-testid="modal"
                    basic={true}
                    trigger={
                        <ResponsiveButton
                            label={t("app.create")}
                            fluid={false}
                            onAction={() => setModalOpen(true)}
                        />
                    }
                    closeOnDimmerClick={false}
                    closeOnEscape={false}
                    open={modalOpen}
                >
                    <Modal.Content>
                        <Detail
                            {...detail.props}
                            onCancel={() => {
                                setModalOpen(false);
                                detail.props.onCancel?.();
                            }}
                            onSave={() => {
                                setModalOpen(false);
                                detail.props.onSave?.();
                            }}
                            collection={collection}
                            collapsible={false}
                            canCreate={canCreate}
                            modal
                        />
                    </Modal.Content>
                </Modal>
            ) : null;
    }

    const buttons = edited ? (
        <>
            <React.Fragment key={`button0`}>
                <ResponsiveButton
                    label={t("app.save")}
                    onAction={onSave!}
                    primary={true}
                    fluid={false}
                />

                <HorizontalSpace />

                <ResponsiveButton
                    label={t("app.cancel")}
                    secondary={true}
                    onAction={onCancel!}
                    fluid={false}
                />
            </React.Fragment>
        </>
    ) : undefined;

    return (
        <CollapsibleSegment
            title={title}
            collapsible={true}
            open={true}
            footer={buttons}
        >
            {table}
            {modalCreate ? (
                <>
                    <VerticalSpace />
                    {modalCreate}
                </>
            ) : undefined}
            {modalEdit}
        </CollapsibleSegment>
    );
};
