import { observer } from "mobx-react-lite";
import React from "react";
import { Dropdown } from "semantic-ui-react";
import { Language } from "erfa-common";
import { useAdminStore, useErfaStore } from "../Root";
import FlexView from "react-flexview/lib";

interface IProps {
    admin: boolean;
}

export const LanguageSelect: React.FC<IProps> = observer(({ admin }) => {
    const useStore = admin ? useAdminStore : useErfaStore;
    const { i18n } = useStore();
    const t = i18n.translate;

    const options = [
        { key: "de", text: t("lang.german"), value: "de" },
        { key: "fr", text: t("lang.french"), value: "fr" },
        { key: "it", text: t("lang.italian"), value: "it" },
    ];
    return (
        <FlexView className={`languageSelect`} hAlignContent={"right"} grow>
            <Dropdown
                compact
                selection
                direction="left"
                value={i18n.language}
                options={options}
                onChange={(_event: React.SyntheticEvent, { value }) => {
                    i18n.setLanguage(value as Language);
                }}
            />
        </FlexView>
    );
});
