import {
    IArrayType,
    IModelType,
    IOptionalIType,
    ISimpleType,
    types,
    _NotCustomized,
    flow,
    getParent,
    Instance,
    hasParent,
    isAlive,
} from "mobx-state-tree";
import { handleDelete } from "erfa-common";
import {
    convertApiError,
    IValidationErrorKey,
    unknownError,
} from "../api/ErrorResponse";
import {
    CreateRecordType,
    RecordType,
    IRecordModel,
    IRestRecordModel,
    KeyType,
} from "./RecordModel";

type RecordKeyType<
    K extends string extends K ? never : string,
    T extends IRecordModel<K, any, any, any>
> = T extends IRecordModel<K, "number", any, any>
    ? number
    : T extends IRecordModel<K, "string", any, any>
    ? string
    : never;

type FullRecordType<
    K extends string extends K ? never : string,
    T extends IRecordModel<K, any, any, any>
> = T["Type"];

type CollectionModelProps<
    K extends string extends K ? never : string,
    T extends IRecordModel<K, any, any, any>
> = {
    items: IOptionalIType<IArrayType<T>, [undefined]>;
    loaded: IOptionalIType<ISimpleType<boolean>, [undefined]>;
};

type CollectionModelActionsAndViews<
    K extends string extends K ? never : string,
    KT extends "string" | "number",
    T extends IRecordModel<K, KT, any, any>
> = {
    load(items: Array<RecordType<T>>): void;
    add(item: CreateRecordType<T>): void;
    removeById(id: KeyType<KT>): void;
    getById(id: KeyType<KT>): FullRecordType<K, T> | undefined;
};

type JoinCollectionModelActionsAndViews<
    K extends string extends K ? never : string,
    KT extends "string" | "number",
    T extends IRecordModel<K, KT, any, any>
> = {
    load(items: Array<RecordType<T>>): void;
    add(item: RecordType<T>): void;
    removeById(id: KeyType<KT>): void;
    getById(id: KeyType<KT>): FullRecordType<K, T> | undefined;
};

export type ICollectionModel<
    K extends string extends K ? never : string,
    KT extends "string" | "number",
    T extends IRecordModel<K, KT, any, any>
> = IModelType<
    CollectionModelProps<K, T>,
    CollectionModelActionsAndViews<K, KT, T>,
    _NotCustomized,
    _NotCustomized
>;

export type IJoinCollectionModel<
    K extends string extends K ? never : string,
    KT extends "string" | "number",
    T extends IRecordModel<K, KT, any, any>
> = IModelType<
    CollectionModelProps<K, T>,
    JoinCollectionModelActionsAndViews<K, KT, T>,
    _NotCustomized,
    _NotCustomized
>;

export type IRestJoinCollectionModel<
    K extends string extends K ? never : string,
    KT extends "string" | "number",
    T extends IRecordModel<K, KT, any, any>
> = IModelType<
    CollectionModelProps<K, T> & {
        errors: IOptionalIType<IArrayType<ISimpleType<string>>, [undefined]>;
    },
    {
        load(): Promise<void>;
        unload(): void;
        add(item: RecordType<T>): Promise<boolean>;
        removeById(id: KeyType<KT>): Promise<void>;
        getById(id: KeyType<KT>): FullRecordType<K, T> | undefined;
        getParentIds(): (number | string)[];
        clearErrors(): void;
    },
    _NotCustomized,
    _NotCustomized
>;

export type IRestCollectionModel<
    K extends string extends K ? never : string,
    KT extends "string" | "number",
    T extends IRecordModel<K, KT, any, any>
> = IModelType<
    CollectionModelProps<K, T> & {
        errors: IOptionalIType<IArrayType<ISimpleType<string>>, [undefined]>;
    },
    {
        load(): Promise<void>;
        unload(): void;
        add(item: CreateRecordType<T>): Promise<boolean>;
        removeById(id: KeyType<KT>): Promise<void>;
        getById(id: KeyType<KT>): FullRecordType<K, T> | undefined;
        getParentIds(): (number | string)[];
        clearErrors(): void;
    },
    _NotCustomized,
    _NotCustomized
>;

type IRestApi<
    K extends string extends K ? never : string,
    T extends IRecordModel<K, any, any, any>
> = {
    resource: string;
    errorMap: Map<IValidationErrorKey, string>;
    all: (ids: (number | string)[]) => Promise<Array<RecordType<T>>>;
    create: (
        ids: (number | string)[],
        values: CreateRecordType<T>
    ) => Promise<RecordType<T>>;
    remove: (ids: (number | string)[]) => Promise<boolean>;
};

type IJoinRestApi<
    K extends string extends K ? never : string,
    T extends IRecordModel<K, any, any, any>
> = {
    resource: string;
    errorMap: Map<IValidationErrorKey, string>;
    all: (ids: (number | string)[]) => Promise<Array<RecordType<T>>>;
    create: (
        ids: (number | string)[],
        values: RecordType<T>
    ) => Promise<RecordType<T>>;
    remove: (ids: (number | string)[]) => Promise<boolean>;
};

export function createCollectionModel<
    K extends string extends K ? never : string,
    KT extends "string" | "number",
    T extends IRecordModel<K, KT, any, any>
>(recordModel: T, key: K, keyType: KT): ICollectionModel<K, KT, T> {
    return types
        .model({
            items: types.optional(types.array(recordModel), []),
            loaded: types.optional(types.boolean, false),
        })
        .actions((self) => ({
            load(items: Array<RecordType<T>>) {
                const itemsToReplace = items.map((item) =>
                    recordModel.create(item)
                );

                self.items.replace(itemsToReplace);
                self.loaded = true;
            },
            add(item: CreateRecordType<T>) {
                self.items.push(recordModel.create(item));
            },
            removeById(id: KeyType<KT>) {
                const index = self.items.findIndex((item) => item[key] === id);
                if (index !== -1) {
                    self.items.splice(index, 1);
                }
            },
        }))
        .views((self) => ({
            getById(id: KeyType<KT>) {
                return self.items.find((item) => item[key] === id);
            },
        }));
}

export function createJoinCollectionModel<
    K extends string extends K ? never : string,
    KT extends "string" | "number",
    T extends IRecordModel<K, KT, any, any>
>(recordModel: T, key: K, keyType: KT): IJoinCollectionModel<K, KT, T> {
    return createCollectionModel(recordModel, key, keyType).actions((self) => ({
        add(item: RecordType<T>) {
            self.items.push(recordModel.create(item));
        },
    }));
}

export function createRestCollectionModel<
    K extends string extends K ? never : string,
    KT extends "string" | "number",
    T extends IRestRecordModel<K, KT, any, any>
>(
    recordModel: T,
    key: K,
    keyType: KT,
    api: IRestApi<K, T>
): IRestCollectionModel<K, KT, T> {
    return createCollectionModel(recordModel, key, keyType)
        .props({
            errors: types.optional(types.array(types.string), []),
        })
        .views((self) => ({
            getParentIds: () => {
                if (hasParent(self)) {
                    const parent = getParent(self) as Instance<
                        IRestRecordModel<"id", any, any, any>
                    >;
                    if (parent.getParentIds) {
                        return parent.getParentIds();
                    }
                }
                return [];
            },
        }))
        .actions((self) => {
            const superLoad = self.load;
            const superRemoveById = self.removeById;
            return {
                load: flow(function* () {
                    if (!self.loaded) {
                        let values: Array<CreateRecordType<T>> | null = null;
                        try {
                            values = yield api.all(self.getParentIds());
                        } catch (e) {
                            if (e.response && isAlive(self)) {
                                const { status, data } = e.response;
                                self.errors.replace(
                                    convertApiError(status, data, api.errorMap)
                                );
                            } else {
                                console.log(e);
                                self.errors.replace([unknownError]);
                            }
                            return;
                        }
                        if (values) {
                            superLoad(values);
                            self.errors.replace([]);
                        }
                    }
                }),
                add: flow(function* add(values: CreateRecordType<T>) {
                    let item: Array<CreateRecordType<T>> | null = null;
                    try {
                        item = yield api.create(self.getParentIds(), values);
                    } catch (e) {
                        if (e.response && isAlive(self)) {
                            const { status, data } = e.response;
                            self.errors.replace(
                                convertApiError(status, data, api.errorMap)
                            );
                        } else {
                            console.log(e);
                            self.errors.replace([unknownError]);
                        }
                    }
                    if (item) {
                        self.items.push(recordModel.create(item));
                        return true;
                    }
                    return false;
                }),
                removeById: flow(function* removeById(id: KeyType<KT>) {
                    if (self.loaded) {
                        yield handleDelete(
                            api.remove(self.getParentIds().concat(id)),
                            api.resource
                        );
                        superRemoveById(id);
                    }
                }),
                unload: () => {
                    self.loaded = false;
                    self.items.replace([]);
                    self.errors.replace([]);
                },
                clearErrors: () => {
                    self.errors.replace([]);
                },
            };
        });
}

export function createRestJoinCollectionModel<
    K extends string extends K ? never : string,
    KT extends "string" | "number",
    T extends IRestRecordModel<K, KT, any, any>
>(
    recordModel: T,
    key: K,
    keyType: KT,
    api: IJoinRestApi<K, T>
): IRestJoinCollectionModel<K, KT, T> {
    return createRestCollectionModel(recordModel, key, keyType, api).actions(
        (self) => ({
            add: flow(function* add(values: RecordType<T>) {
                let item: Array<CreateRecordType<T>> | null = null;
                try {
                    item = yield api.create(self.getParentIds(), values);
                } catch (e) {
                    if (e.response && isAlive(self)) {
                        const { status, data } = e.response;
                        self.errors.replace(
                            convertApiError(status, data, api.errorMap)
                        );
                    } else {
                        console.log(e);
                        self.errors.replace([unknownError]);
                    }
                }
                if (item) {
                    self.items.push(recordModel.create(item));
                    return true;
                }
                return false;
            }),
        })
    );
}

export const unloadedCollection = {
    items: [],
    loaded: false,
};

export const unloadedRestCollection = {
    items: [],
    loaded: false,
    errors: [],
};
