import {
    IAdminOauthClientRequest,
    IAdminOauthClientResponse,
    IAdminOauthClientAuthorizeResponse,
} from "erfa-common";
import { requests } from "../../requests";

export const AdminOauthClientsApi = {
    all: async () =>
        (await requests.get(`/admin/oauth/clients`, {})) as Promise<
            IAdminOauthClientResponse[]
        >,
    add: async (client: IAdminOauthClientRequest) =>
        requests.post("/admin/oauth/clients", client) as Promise<
            IAdminOauthClientResponse
        >,
    delete: async (clientId: string) =>
        requests.delete(`/admin/oauth/clients/${clientId}`) as Promise<boolean>,
    authorize: async (clientId: string, redirectUri: string) =>
        requests.get(
            `/admin/oauth/authorize?client_id=${clientId}&response_type=code&redirect_uri=${encodeURI(
                redirectUri
            )}`
        ) as Promise<IAdminOauthClientAuthorizeResponse>,
    decisionAllow: async (transactionId: string) =>
        requests.post(`/admin/oauth/authorize`, {
            transaction_id: transactionId,
        }) as Promise<{}>,
    decisionDeny: async (transactionId: string) =>
        requests.post(`/admin/oauth/authorize`, {
            transaction_id: transactionId,
            cancel: true,
        }) as Promise<{}>,
};
