import React, { useRef } from "react";
import { Dropdown, DropdownProps, TranslateFunction } from "components";
import "./styles/starCategoryDropdown.css";
import { StarCategoryAPI } from "erfa-common";

export interface IStarCategoryOptions {
    display: string;
    value: StarCategoryAPI;
}

export const star = "\uF005";

export const Stars: React.FC<{ count: number }> = ({ count }) => {
    return (
        <div
            style={{
                display: "inline",
                fontFamily: "Icons",
                color: "orange",
            }}
        >
            {star.repeat(count)}
        </div>
    );
};

export const getStarCategoryOptions = (
    translate: TranslateFunction
): IStarCategoryOptions[] => [
    {
        display: ((<Stars count={5} />) as unknown) as string,
        value: StarCategoryAPI.FIVE,
    },
    {
        display: ((<Stars count={4} />) as unknown) as string,
        value: StarCategoryAPI.FOUR,
    },
    {
        display: ((<Stars count={3} />) as unknown) as string,
        value: StarCategoryAPI.THREE,
    },
    {
        display: ((<Stars count={2} />) as unknown) as string,
        value: StarCategoryAPI.TWO,
    },
    {
        display: ((<Stars count={1} />) as unknown) as string,
        value: StarCategoryAPI.ONE,
    },
    {
        display: translate("star.swisslodge"),
        value: StarCategoryAPI.SWISS_LODGE,
    },
    {
        display: translate("star.restaurant"),
        value: StarCategoryAPI.RESTAURANT,
    },
    {
        display: translate("star.headoffice"),
        value: StarCategoryAPI.HEAD_OFFICE,
    },
];

type IProps = Omit<
    DropdownProps & { translate: TranslateFunction },
    "className" | "options" | "placeholder" | "search"
>;

export const StarCategoryDropdown: React.FC<IProps> = (props) => {
    const options = useRef<IStarCategoryOptions[]>(
        getStarCategoryOptions(props.translate)
    );

    return (
        <Dropdown
            {...props}
            className={`starCategoryDropdown${
                props.value === undefined || props.value === null
                    ? " outline-icons"
                    : ""
            }`}
            options={options.current}
            placeholder={star.repeat(5)}
            search={false}
        />
    );
};
