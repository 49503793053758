import { types, Instance, getRoot } from "mobx-state-tree";
import { createRestCollectionModel } from "../../Framework/models/CollectionModel";
import { UnpublishedRecordsApi } from "../api";

import {
    SalaryBasisArray,
    SalaryBasis,
    removeUndefined,
    IUnpublishedRecordsResponse,
    calculateMonthlySalary,
    WeeklyHours,
} from "erfa-common";

import {
    createRestRecordModel,
    ModelInterface,
} from "../../Framework/models/RecordModel";
import { IErfaModel } from "./Erfa";

const recordToRequest = ({
    positionId,
    salaryBasis,
    age,
    yearsOfEmployment,
    degreeOfEmployment,
    contractSalary,
    bonusSalary,
}: {
    positionId: number | undefined;
    salaryBasis: SalaryBasis | undefined;
    age: number | undefined;
    yearsOfEmployment: number | undefined;
    degreeOfEmployment: number | undefined;
    contractSalary: number | undefined;
    bonusSalary: number | undefined;
}) =>
    removeUndefined({
        position_id: positionId,
        salary_basis: salaryBasis,
        age: age,
        years_of_employment: yearsOfEmployment,
        degree_of_employment: degreeOfEmployment,
        contract_salary: contractSalary,
        bonus_salary: bonusSalary,
    });

const responseToRecord = ({
    id,
    position_id,
    salary_basis,
    age,
    years_of_employment,
    degree_of_employment,
    contract_salary,
    bonus_salary,
}: IUnpublishedRecordsResponse) => ({
    id,
    positionId: position_id,
    salaryBasis: salary_basis,
    age,
    yearsOfEmployment: years_of_employment,
    degreeOfEmployment: degree_of_employment,
    contractSalary: contract_salary,
    bonusSalary: bonus_salary,
});

export const UnpublishedRecord = createRestRecordModel(
    "id",
    "number",
    {
        positionId: types.maybe(types.number),
        salaryBasis: types.maybe(types.enumeration([...SalaryBasisArray])),
        age: types.maybe(types.number),
        yearsOfEmployment: types.maybe(types.number),
        degreeOfEmployment: types.maybe(types.number),
        contractSalary: types.maybe(types.number),
        bonusSalary: types.maybe(types.number),
    },
    {},
    {
        errorMap: new Map(),

        update: async (idPath, updateRecord) => {
            const record = await UnpublishedRecordsApi.update(
                idPath[0] as number,
                recordToRequest(updateRecord)
            );

            return responseToRecord(record);
        },
    }
).views((self) => ({
    get salary(): number | undefined {
        const weeklyHours = (getRoot(self) as IErfaModel).weeklyHours
            .value as WeeklyHours;
        const { salaryBasis, contractSalary, degreeOfEmployment } = self;
        return calculateMonthlySalary(
            salaryBasis,
            contractSalary,
            degreeOfEmployment,
            weeklyHours
        );
    },
}));

export const UnpublishedRecords = createRestCollectionModel(
    UnpublishedRecord,
    "id",
    "number",
    {
        errorMap: new Map(),
        resource: "unpublished_record",

        all: async (_idPath) => {
            const records = await UnpublishedRecordsApi.all();
            return records.map(responseToRecord);
        },

        create: async (_idPath, newRecord) => {
            const record = await UnpublishedRecordsApi.create(
                recordToRequest(newRecord)
            );

            return responseToRecord(record);
        },

        remove: async (idPath) =>
            await UnpublishedRecordsApi.remove(idPath[0] as number),
    }
);

export type IUnpublishedRecord = ModelInterface<typeof UnpublishedRecord>;
export type IUnpublishedRecordInstance = Instance<typeof UnpublishedRecord>;
export type IUnpublishedRecordsInstance = Instance<typeof UnpublishedRecords>;
