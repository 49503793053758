import { observer } from "mobx-react-lite";
import React, { ReactElement } from "react";
import {
    Switch,
    Route,
    Redirect,
    useLocation,
    RouteProps,
} from "react-router-dom";
import { AdminRoutes, OauthRoutes } from "./admin/routes";
import { ForbiddenPage, NotFoundPage } from "./erfa_components";
import { LanguageSelect } from "./erfa_components/LanguageSelect";
//import { TestingPanel } from "./erfa_components/TestingPanel";
import { Login, Routes, Logout } from "./erfa/routes";
import { AdminLogin } from "admin/routes";
import { useAdminStore, useErfaStore } from "./Root";
import history from "erfaHistory";
import { AdminLogout } from "./admin/routes/AdminLogout";
import "./body.css";

const App: React.FC = () => {
    const isAdmin = history.location.pathname.startsWith("/admin");

    return (
        <>
            {/* <TestingPanel /> */}
            {isAdmin ? <AdminApp /> : <ErfaApp />}
        </>
    );
};

interface IBaseRouteProps {
    admin?: boolean;
    exact: boolean;
    path: string;
    children: ReactElement;
}

const BaseRoute: React.FC<IBaseRouteProps> = ({
    admin,
    exact,
    path,
    children,
}) => (
    <Route exact={exact} path={path}>
        <LanguageSelect admin={admin === true} />
        {children}
    </Route>
);

interface ISecuredRouteProps extends RouteProps {
    user: number | undefined;
    redirectURL: string;
}

const SecuredRoute: React.FC<ISecuredRouteProps> = ({
    user,
    redirectURL,
    children,
    ...props
}) => {
    const location = useLocation();
    return (
        <Route props>
            {user ? (
                children
            ) : (
                <Redirect
                    to={{
                        pathname: redirectURL,
                        state: {
                            referrer: `${location.pathname}${
                                location.search ?? ""
                            }`,
                        },
                    }}
                />
            )}
        </Route>
    );
};

const ErfaApp: React.FC = observer(() => {
    const user = localStorage.getItem("user");
    const location = useLocation();
    const { i18n } = useErfaStore();
    i18n.load();

    return i18n[i18n.language].loaded ? (
        <>
            <Switch>
                <BaseRoute exact path="/forbidden">
                    <ForbiddenPage admin={false} />
                </BaseRoute>
                <BaseRoute exact path="/not_found">
                    <NotFoundPage admin={false} />
                </BaseRoute>

                <BaseRoute exact path="/login">
                    <Login />
                </BaseRoute>
                <BaseRoute exact path="/logout">
                    <Logout />
                </BaseRoute>
                <Route path="/">
                    {user ? (
                        <Routes />
                    ) : (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: { referrer: location.pathname },
                            }}
                        />
                    )}
                </Route>
            </Switch>
        </>
    ) : (
        <></>
    );
});

const AdminApp: React.FC = observer(() => {
    let adminUser = localStorage.getItem("admin_user");

    const { i18n, setUser, user } = useAdminStore();
    i18n.load();
    setUser(adminUser ? parseInt(adminUser, 10) : undefined);

    if (!document.documentElement.classList.contains("adminApp")) {
        document.documentElement.classList.add("adminApp");
    }

    return i18n[i18n.language].loaded ? (
        <>
            <Switch>
                <BaseRoute admin exact path="/admin/forbidden">
                    <ForbiddenPage admin={true} />
                </BaseRoute>
                <BaseRoute admin exact path="/admin/not_found">
                    <NotFoundPage admin={true} />
                </BaseRoute>

                <BaseRoute admin exact path="/admin/login">
                    <AdminLogin />
                </BaseRoute>
                <Route exact path="/admin/logout">
                    <AdminLogout />
                </Route>

                <SecuredRoute
                    path="/admin/oauth"
                    user={user}
                    redirectURL="/admin/login"
                >
                    <OauthRoutes />
                </SecuredRoute>
                <SecuredRoute
                    path="/admin"
                    user={user}
                    redirectURL="/admin/login"
                >
                    <AdminRoutes />
                </SecuredRoute>
            </Switch>
        </>
    ) : (
        <></>
    );
});

export default App;
