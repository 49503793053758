import { observer } from "mobx-react-lite";
import React from "react";
import { Table } from "semantic-ui-react";
import { useAdminStore, useErfaStore } from "../Root";
import { ResultsSegmentMessage } from "./ResultsSegmentMessage";
import { borderStyle, IColumnResult } from "./ResultsTable";

interface IResultsTableProps {
    isAdmin?: boolean;
}

export interface ITableResultsTotal {
    positionId: number;
    contractQ1: number;
    contractMedian: number;
    contractQ3: number;
    bonusQ1: number;
    bonusMedian: number;
    bonusQ3: number;
    quality: string;
}

export const ResultTotalColumnNames = [
    { value: "filter.position", key: "position" },
    { value: "results.quartile1", key: "contractQ1" },
    { value: "results.median", key: "contractMedian" },
    { value: "results.quartile3", key: "contractQ3" },
    { value: "results.quartile1", key: "bonusQ1" },
    { value: "results.median", key: "bonusMedian" },
    { value: "results.quartile3", key: "bonusQ3" },
    { value: "results.quality", key: "quality" },
];
export const ResultsTotalTable: React.FC<IResultsTableProps> = observer(
    ({ isAdmin }) => {
        const { positions, resultsTotal, i18n } = isAdmin
            ? useAdminStore()
            : useErfaStore();
        positions.load();
        resultsTotal.load();

        const t = i18n.translate;
        const columns: IColumnResult[] = ResultTotalColumnNames.map(
            ({ value, key }) => ({
                value: t(value),
                key,
            })
        );

        return resultsTotal.errorMessage || resultsTotal.items.length === 0 ? (
            <ResultsSegmentMessage text={t("app.noResultsFound")} />
        ) : (
            <Table
                unstackable
                style={{
                    width: "100%",
                }}
                celled
                basic={"very"}
            >
                <Table.Header>
                    <Table.Row textAlign={"left"} verticalAlign="middle">
                        <Table.HeaderCell />
                        <Table.HeaderCell colSpan="3">
                            {t("results.contractSalary")}
                        </Table.HeaderCell>
                        <Table.HeaderCell colSpan="4">
                            {t("results.bonus")}
                        </Table.HeaderCell>
                    </Table.Row>
                    <Table.Row textAlign={"right"} verticalAlign="middle">
                        {columns.map((header) => (
                            <Table.HeaderCell
                                key={`headerCell_${header.key}`}
                                style={{
                                    borderBottom: borderStyle,
                                }}
                            >
                                {header.value}
                            </Table.HeaderCell>
                        ))}
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {resultsTotal.items.map((result, index) => (
                        <ResultsRow
                            columns={columns}
                            result={result}
                            isAdmin={isAdmin}
                            key={index}
                        />
                    ))}
                </Table.Body>
            </Table>
        );
    }
);

const ResultsRow: React.FC<{
    columns: IColumnResult[];
    result: ITableResultsTotal;
    isAdmin?: boolean;
}> = observer(({ columns, result, isAdmin }) => {
    const { i18n, positions } = isAdmin ? useAdminStore() : useErfaStore();
    const t = i18n.translate;

    return (
        <Table.Row>
            {columns.map(({ key }, index) => {
                let value = result[key];
                let className = "";

                if (key === "position") {
                    value = positions.findPositionById(result.positionId)
                        ?.description;
                }

                if (key === "quality") {
                    value = t(`quality.${result[key]}`);
                    className = `quality-${result[key]}`;
                }

                return (
                    <Table.Cell
                        key={`bodycell_${result.positionId}_${index + 1}`}
                        textAlign={
                            ["position", "quality"].includes(key)
                                ? "left"
                                : "right"
                        }
                        className={className}
                    >
                        {value}
                    </Table.Cell>
                );
            })}
        </Table.Row>
    );
});
