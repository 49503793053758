import React from "react";
import { Message } from "semantic-ui-react";
import { VerticalSpace } from "components";

interface IProps {
    t: (key: string) => string;
}

export const PublishMessage: React.FC<IProps> = ({ t }) => {
    const contentKey = "publishMessage.content";

    return (
        <div style={{ position: "relative" }}>
            <Message style={{ margin: 0, zIndex: 1 }} warning>
                <Message.Content>{t(contentKey)}</Message.Content>
            </Message>
            <VerticalSpace small />
        </div>
    );
};
