import {
    CollapsibleSegment,
    DropdownOption,
    DropdownValue,
    MultiDropdown,
} from "components";
import {
    AgeBracket,
    DegreeOfEmployment,
    SalaryBasis,
    StarCategoryType,
    TourismRegionArray,
    TourismRegionType,
    TourismZoneArray,
    TourismZoneType,
    YearsOfEmployment,
} from "erfa-common";
import { observer } from "mobx-react-lite";
import React from "react";
import { Form } from "semantic-ui-react";
import { IAdminResultTotalInstance } from "../admin/models";
import { useAdminStore, useErfaStore } from "../Root";
import { MultiStarCategoryDropdown } from "./MultiStarCategoryDropdown";

interface IProps {
    isAdmin?: boolean;
}

export const FilterTotalSegment: React.FC<IProps> = observer(({ isAdmin }) => {
    const { filterTotal: filterStore, resultsTotal, i18n } = isAdmin
        ? useAdminStore()
        : useErfaStore();

    const t = i18n.translate;
    const NO_RESULTS_MESSAGE_KEY = "app.noResultsFound";

    const salaryBasisOptions: DropdownOption[] = [
        {
            display: t("filter.salaryBasis.monthly"),
            value: "month",
        },
        {
            display: t("filter.salaryBasis.hourly"),
            value: "hour",
        },
        {
            display: t("filter.salaryBasis.daily"),
            value: "day",
        },
    ];

    const tourismRegionOptions = TourismRegionArray.map(
        (tourismRegion): DropdownOption => ({
            display: t(`tourismusregion.${tourismRegion}`),
            value: tourismRegion,
        })
    );

    const tourismZoneOptions = TourismZoneArray.map(
        (tourismZone): DropdownOption => ({
            display: t(`tourismuszone.${tourismZone}`),
            value: tourismZone,
        })
    );

    const setResultCopiedToFalse = () => {
        if (isAdmin) {
            (resultsTotal as IAdminResultTotalInstance).setResultsCopied(false);
        }
    };

    return (
        <CollapsibleSegment title={t("app.filter")} collapsible open={true}>
            <Form noValidate>
                <MultiDropdown
                    label={t("filter.salaryBasis")}
                    values={[...filterStore.values.salaryBasis]}
                    options={salaryBasisOptions}
                    onChange={(values: DropdownValue[]) => {
                        filterStore.values.setSalaryBasis(
                            values as SalaryBasis[]
                        );
                        resultsTotal.reload();
                        setResultCopiedToFalse();
                    }}
                    search={false}
                />
                <MultiDropdown
                    label={t("filter.ageBracket")}
                    values={[...filterStore.values.ageBracket]}
                    options={ageBracketOptions}
                    onChange={(values: DropdownValue[]) => {
                        filterStore.values.setAgeBracket(
                            values as AgeBracket[]
                        );
                        resultsTotal.reload();
                        setResultCopiedToFalse();
                    }}
                    noResultsMessage={t(NO_RESULTS_MESSAGE_KEY)}
                />
                <MultiDropdown
                    label={t("filter.yearsOfEmployment")}
                    values={[...filterStore.values.yearsOfEmployment]}
                    options={yearsOfEmploymentOptions}
                    onChange={(values: DropdownValue[]) => {
                        filterStore.values.setYearsOfEmployment(
                            values as YearsOfEmployment[]
                        );
                        resultsTotal.reload();
                        setResultCopiedToFalse();
                    }}
                    noResultsMessage={t(NO_RESULTS_MESSAGE_KEY)}
                />
                <MultiDropdown
                    label={t("filter.degreeOfEmployment")}
                    values={[...filterStore.values.degreeOfEmployment]}
                    options={degreeOfEmploymentOptions}
                    search={false}
                    onChange={(values: DropdownValue[]) => {
                        filterStore.values.setDegreeOfEmployment(
                            values as DegreeOfEmployment[]
                        );
                        resultsTotal.reload();
                        setResultCopiedToFalse();
                    }}
                />
                <MultiStarCategoryDropdown
                    label={t("filter.starRating")}
                    values={[...filterStore.values.starCategory]}
                    onChange={(values: DropdownValue[]) => {
                        filterStore.values.setStarCategory(
                            values as StarCategoryType[]
                        );
                        resultsTotal.reload();
                        setResultCopiedToFalse();
                    }}
                    noResultsMessage={t(NO_RESULTS_MESSAGE_KEY)}
                    translate={t}
                />
                <MultiDropdown
                    label={t("filter.tourismRegion")}
                    values={[...filterStore.values.tourismRegion]}
                    options={tourismRegionOptions}
                    search
                    noResultsMessage={t(NO_RESULTS_MESSAGE_KEY)}
                    onChange={(values: DropdownValue[]) => {
                        filterStore.values.setTourismRegion(
                            values as TourismRegionType[]
                        );
                        resultsTotal.reload();
                        setResultCopiedToFalse();
                    }}
                />
                <MultiDropdown
                    label={t("filter.tourismZone")}
                    values={[...filterStore.values.tourismZone]}
                    options={tourismZoneOptions}
                    search={false}
                    onChange={(values: DropdownValue[]) => {
                        filterStore.values.setTourismZone(
                            values as TourismZoneType[]
                        );
                        resultsTotal.reload();
                        setResultCopiedToFalse();
                    }}
                />
            </Form>
        </CollapsibleSegment>
    );
});

const ageBracketOptions: DropdownOption[] = [
    {
        display: "< 30",
        value: "0-29",
    },
    {
        display: "30 - 39",
        value: "30-39",
    },
    {
        display: "40 - 49",
        value: "40-49",
    },
    {
        display: "50+",
        value: "50-100",
    },
];

const yearsOfEmploymentOptions: DropdownOption[] = [
    {
        display: "< 5",
        value: "0-4",
    },
    {
        display: "5 - 10",
        value: "5-10",
    },
    {
        display: "11 - 15",
        value: "11-15",
    },
    {
        display: "16 - 19",
        value: "16-19",
    },
    {
        display: "20+",
        value: "20-100",
    },
];

const degreeOfEmploymentOptions: DropdownOption[] = [
    {
        display: "0 - 10%",
        value: "0-10",
    },
    {
        display: "11 - 20%",
        value: "11-20",
    },
    {
        display: "21 - 30%",
        value: "21-30",
    },
    {
        display: "31 - 40%",
        value: "31-40",
    },
    {
        display: "41 - 50%",
        value: "41-50",
    },
    {
        display: "51 - 60%",
        value: "51-60",
    },
    {
        display: "61 - 70%",
        value: "61-70",
    },
    {
        display: "71 - 80%",
        value: "71-80",
    },
    {
        display: "81 - 90%",
        value: "81-90",
    },
    {
        display: "91 - 100%",
        value: "91-100",
    },
];
