import React from "react";
import { TranslateFunction } from "components";
import { observer } from "mobx-react-lite";
import { ResultsQuality as ResultsQualityType } from "erfa-common";
import FlexView from "react-flexview";

interface IProps {
    translate: TranslateFunction;
    quality: ResultsQualityType;
}
export const ResultsQuality: React.FC<IProps> = observer(
    ({ translate: t, quality }) => {
        const qualityColor = { low: "red", medium: "orange", high: "green" };

        return (
            <FlexView>
                {`${t("results.quality")}:`}&nbsp;
                <div style={{ color: qualityColor[quality] }}>
                    {t(`quality.${quality}`)}
                </div>
            </FlexView>
        );
    }
);
