import { useContext, createContext } from "react";
import { AdminModel, IAdminModel } from "./admin/models";
import { ErfaModel, IErfaModel } from "./erfa/models";

export const getErfaStore = () => {
    const erfaStore = ErfaModel.create();

    return erfaStore;
};

export const getAdminStore = () => {
    const adminStore = AdminModel.create();

    return adminStore;
};

const ErfaStoreContext = createContext<null | IErfaModel>(null);
export const ErfaProvider = ErfaStoreContext.Provider;

const AdminStoreContext = createContext<null | IAdminModel>(null);
export const AdminProvider = AdminStoreContext.Provider;

export function useErfaStore() {
    const store = useContext(ErfaStoreContext);
    if (store === null) {
        throw new Error("Store cannot be null, please add a context provider");
    }

    return store;
}

export function useAdminStore() {
    const store = useContext(AdminStoreContext);
    if (store === null) {
        throw new Error("Store cannot be null, please add a context provider");
    }

    return store;
}
