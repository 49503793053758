import React from "react";
import { ViewContainer } from "components";
import { ResultsTotalSegment } from "../../erfa_components";
import { useErfaStore } from "../../Root";
import { HelpMessage } from "../../erfa_components/HelpMessage";
import { FilterTotalSegment } from "../../erfa_components/FilterTotalSegment";
import FlexView from "react-flexview/lib";
import { PublishMessage } from "../../erfa_components/PublishMessage";

export const FilterTotal: React.FC = () => {
    const store = useErfaStore();
    const { newData } = store;
    if (!newData.published) {
        return <PublishMessage t={store.i18n.translate} />;
    }
    return (
        <ViewContainer>
            <HelpMessage t={store.i18n.translate} type={"filter"} />
            <FlexView
                marginLeft="-0.5em"
                marginRight="-0.5em"
                marginTop="-0.5em"
                marginBottom="-0.5em"
                wrap
            >
                <FlexView
                    grow={1}
                    basis="1em"
                    marginLeft="0.5em"
                    marginRight="0.5em"
                    marginTop="0.5em"
                    marginBottom="0.5em"
                    style={{ minWidth: "min-content" }}
                    column
                >
                    <FilterTotalSegment />
                </FlexView>

                <FlexView
                    grow={2}
                    basis="1em"
                    marginLeft="0.5em"
                    marginRight="0.5em"
                    marginTop="0.5em"
                    marginBottom="0.5em"
                    style={{ minWidth: "min-content" }}
                    column
                >
                    <ResultsTotalSegment />
                </FlexView>
            </FlexView>
        </ViewContainer>
    );
};
