import { types, Instance } from "mobx-state-tree";

import {
    SalaryBasisArray,
    SalaryBasis,
    AgeBracketArray,
    AgeBracket,
    YearsOfEmploymentArray,
    YearsOfEmployment,
    DegreeOfEmploymentArray,
    DegreeOfEmployment,
    StarCategoryArray,
    StarCategoryType,
    TourismRegionArray,
    TourismRegionType,
    TourismZoneArray,
    TourismZoneType,
} from "erfa-common";

const FilterTotal = types
    .model({
        salaryBasis: types.optional(
            types.array(types.enumeration([...SalaryBasisArray])),
            []
        ),

        ageBracket: types.optional(
            types.array(types.enumeration([...AgeBracketArray])),
            []
        ),

        yearsOfEmployment: types.optional(
            types.array(types.enumeration([...YearsOfEmploymentArray])),
            []
        ),

        degreeOfEmployment: types.optional(
            types.array(types.enumeration([...DegreeOfEmploymentArray])),
            []
        ),

        starCategory: types.optional(
            types.array(types.enumeration([...StarCategoryArray])),
            []
        ),
        tourismRegion: types.optional(
            types.array(types.enumeration([...TourismRegionArray])),
            []
        ),
        tourismZone: types.optional(
            types.array(types.enumeration([...TourismZoneArray])),
            []
        ),
    })
    .actions((self) => ({
        setSalaryBasis(salaryBasis: SalaryBasis[]) {
            self.salaryBasis.replace(salaryBasis);
        },
        setAgeBracket(ageBracket: AgeBracket[]) {
            self.ageBracket.replace(ageBracket);
        },
        setYearsOfEmployment(yearsOfEmployment: YearsOfEmployment[]) {
            self.yearsOfEmployment.replace(yearsOfEmployment);
        },
        setDegreeOfEmployment(degreeOfEmployment: DegreeOfEmployment[]) {
            self.degreeOfEmployment.replace(degreeOfEmployment);
        },
        setStarCategory(starCategory: StarCategoryType[]) {
            self.starCategory.replace(starCategory);
        },
        setTourismRegion(tourismRegion: TourismRegionType[]) {
            self.tourismRegion.replace(tourismRegion);
        },
        setTourismZone(tourismZone: TourismZoneType[]) {
            self.tourismZone.replace(tourismZone);
        },
    }));

export const FilterTotalModel = types.model({
    values: types.optional(FilterTotal, {}),
});

export type IFilterTotalInstance = Instance<typeof FilterTotal>;
export type IFilterTotalModelInstance = Instance<typeof FilterTotalModel>;
