import { requests } from "../../requests";
import { IWeeklyHoursRequest, IWeeklyHoursResponse } from "erfa-common";

export const WeeklyHoursApi = {
    get: async () =>
        (await requests.get("/weekly-hours")) as Promise<IWeeklyHoursResponse>,

    update: async (updateRecord: IWeeklyHoursRequest) =>
        (await requests.put(
            `/weekly-hours`,
            updateRecord
        )) as Promise<IWeeklyHoursResponse>,
};
