import React, { useState } from "react";
import {
    Modal,
    Segment,
    Dropdown as UIDropdown,
    Button,
} from "semantic-ui-react";
import FlexView from "react-flexview";
import { observer } from "mobx-react-lite";
import { IRestCollectionModel } from "./models/CollectionModel";
import { createDetail, IDetailProps } from "./Detail";
import { IRestRecordModel } from "./models/RecordModel";
import { HorizontalSpace, PrimaryButton, VerticalSpace } from "components";
import { ITableProps, Table } from "components";
import { Instance } from "mobx-state-tree";
import { extractComponent } from "./utils/extractComponent";

type DetailRecordModelKey<T> = T extends ITableViewProps<infer K, any>
    ? K
    : never;

interface ITableViewProps<
    K extends string extends K ? never : string,
    T extends IRestCollectionModel<K, any, any>
> {
    title: string;
    canCreate?: boolean;
    children: React.ReactNode;
    collection: Instance<T>;
    actions?: Array<{ label: string; onClick: () => void }>;
    translate: (key: string) => string;
}

export const TableView: <
    K extends string extends K ? never : string,
    T extends IRestCollectionModel<K, any, any>
>(
    props: ITableViewProps<K, T>
) => React.ReactElement | null = observer((props) => {
    type K = DetailRecordModelKey<typeof props>;
    const {
        title,
        children,
        canCreate,
        collection,
        actions,
        translate: t,
    } = props;
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    let modalCreate: React.ReactElement | null = null;

    let tableComponent: React.ReactElement<ITableProps> | null = null;
    let detailComponent: React.ReactElement<
        IDetailProps<K, any, IRestRecordModel<K, any, any, any>>
    > | null = null;

    const Detail = createDetail({} as IRestRecordModel<K, any, any, any>);

    const childArray = React.Children.toArray(children) as React.ReactElement[];
    childArray.forEach((child) => {
        const extractedComponents = extractComponent(
            child,
            {
                detail: createDetail({} as IRestRecordModel<K, any, any, any>),
                table: Table,
            },
            2
        );
        if (extractedComponents.detail) {
            detailComponent = extractedComponents.detail as React.ReactElement<
                IDetailProps<K, any, IRestRecordModel<K, any, any, any>>
            >;
        }
        if (extractedComponents.table) {
            tableComponent = extractedComponents.table as React.ReactElement<ITableProps>;
        }
    });

    if (!tableComponent) {
        throw new Error("Missing table component");
    }

    const table = tableComponent as React.ReactElement<ITableProps>;
    if (detailComponent) {
        const detail = detailComponent as React.ReactElement<
            IDetailProps<K, any, IRestRecordModel<K, any, any, any>>
        >;
        const detailCreate = (
            <Detail
                {...detail.props}
                onCancel={() => {
                    setModalOpen(false);
                    detail.props.onCancel?.();
                }}
                onSave={() => {
                    setModalOpen(false);
                    detail.props.onSave?.();
                }}
                collection={collection}
                collapsible={false}
                canCreate={canCreate}
                modal
                translate={t}
            />
        );

        modalCreate =
            canCreate !== false ? (
                <Modal
                    data-testid="modal"
                    basic={true}
                    trigger={
                        <PrimaryButton
                            label={t("app.create")}
                            fluid={false}
                            onAction={() => setModalOpen(true)}
                        />
                    }
                    closeOnDimmerClick={false}
                    closeOnEscape={false}
                    open={modalOpen}
                >
                    <Modal.Content>{detailCreate}</Modal.Content>
                </Modal>
            ) : null;
    }

    return (
        <>
            <FlexView>
                <FlexView hAlignContent={"left"}>
                    <h1>{title.toLocaleUpperCase()}</h1>
                </FlexView>
                <FlexView hAlignContent={"right"} marginLeft={"auto"}>
                    {modalCreate}
                </FlexView>
                <FlexView>
                    {actions?.length && (
                        <>
                            <HorizontalSpace small />
                            <UIDropdown
                                trigger={<Button icon="ellipsis vertical" />}
                                icon={null}
                                direction="left"
                            >
                                <UIDropdown.Menu>
                                    {actions.map((action, index) => (
                                        <UIDropdown.Item
                                            key={`${index}`}
                                            onClick={() => action.onClick()}
                                        >
                                            {action.label}
                                        </UIDropdown.Item>
                                    ))}
                                </UIDropdown.Menu>
                            </UIDropdown>
                        </>
                    )}
                </FlexView>
            </FlexView>
            <VerticalSpace small />
            <Segment className={"tableSegment"}>{table}</Segment>
        </>
    );
});
