import { requests } from "requests";

interface IAdminUserLogin {
    id: number;
    username: string;
}

export const AdminAuthenticationApi = {
    login: async (username: string, password: string) =>
        requests.post(
            "/admin/login",
            {},
            {},
            {
                auth: {
                    username,
                    password,
                },
            }
        ) as Promise<IAdminUserLogin>,
    logout: async () =>
        (await requests.post("/admin/logout", {})) as Promise<void>,
};
