import React from "react";
import { LoggedOutLayout, PrimaryButton, VerticalSpace } from "components";
import { MirusLogo } from "erfa_components/MirusLogo";
import FlexView from "react-flexview";
import { useHistory } from "react-router-dom";
import { useAdminStore, useErfaStore } from "../Root";

export const NotFoundPage: React.FC<{ admin: boolean }> = ({ admin }) => {
    const history = useHistory();
    const useStore = admin ? useAdminStore : useErfaStore;
    const t = useStore().i18n.translate;
    return (
        <LoggedOutLayout>
            <MirusLogo />
            <FlexView column hAlignContent="center">
                <h1>404: {t("app.notFound")}</h1>
                {t("app.notFoundError")}
                <VerticalSpace />
                <PrimaryButton
                    onAction={() => history.replace(admin ? "/admin" : "/")}
                    label={t("app.toHomepage")}
                />
            </FlexView>
        </LoggedOutLayout>
    );
};
