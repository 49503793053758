import { IResultsTotalResponse } from "erfa-common";
import { requests } from "../../requests";

export const ResultsTotalApi = {
    getCompanyResultsTotal: async (queryParams: string) => {
        return (await requests.get(
            `/company-results-total?${queryParams}`,
            {}
        )) as Promise<IResultsTotalResponse>;
    },

    getAllResultsTotal: async (queryParams: string) => {
        return (await requests.get(
            `/admin/results-total?${queryParams}`,
            {}
        )) as Promise<IResultsTotalResponse>;
    },
};
