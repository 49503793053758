import { flow, types, Instance } from "mobx-state-tree";
import { convertApiError } from "../../Framework/api";
import { NewDataApi, IPublishedStatus } from "../api";

export const NewData = types
    .model({
        published: types.maybeNull(types.boolean),
        isPublishedLoaded: types.optional(types.boolean, false),
        hr30: types.maybeNull(types.boolean),
        isOpenModalQuestion: types.optional(types.boolean, false),
        isOpenModalResult: types.optional(types.boolean, false),
        isOpenModalError: types.optional(types.boolean, false),

        errors: types.optional(types.array(types.string), []),
    })
    .actions((self) => ({
        loadPublished: flow(function* () {
            if (!self.isPublishedLoaded) {
                let isPublished: null | IPublishedStatus = null;

                try {
                    isPublished = yield NewDataApi.isPublished();
                } catch (e) {
                    if (e.response) {
                        const { status, data } = e.response;
                        self.errors.replace(convertApiError(status, data));
                    } else {
                        console.log(e);
                        self.errors.replace(["app.unknownError"]);
                    }

                    return;
                }

                if (isPublished) {
                    self.published = isPublished.published;
                    self.hr30 = isPublished.hr30;
                    self.errors.replace([]);
                    self.isPublishedLoaded = true;
                }
            }
        }),
        publish: flow(function* () {
            try {
                yield NewDataApi.publish();
                self.isOpenModalResult = true;
            } catch (e) {
                self.isOpenModalError = true;

                if (e.response) {
                    const { status, data } = e.response;
                    self.errors.replace(
                        convertApiError(status, data, NewDataApi.errorMap)
                    );
                } else {
                    self.errors.replace(["app.unknownError"]);
                }
            }
        }),
        setPublished: (published: boolean) => {
            self.published = published;
        },
        setIsOpenModalQuestion: (open: boolean) => {
            self.isOpenModalQuestion = open;
        },
        setIsOpenModalResult: (open: boolean) => {
            self.isOpenModalResult = open;
        },
        setIsOpenModalError: (open: boolean) => {
            self.isOpenModalError = open;
        },
    }));

export type INewDataInstance = Instance<typeof NewData>;
