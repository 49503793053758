import React from "react";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react-lite";
import moment from "moment";
import {
    CollapsibleSegment,
    Dropdown,
    ResponsiveButton,
    SimpleModal,
    ViewContainer,
} from "components";
import { useErfaStore } from "../../Root";
import { NewDataTable } from "../../erfa_components";
import { HelpMessage } from "../../erfa_components/HelpMessage";
import "./styles/newData.css";
import { Form } from "semantic-ui-react";
import { WeeklyHours } from "erfa-common";

export const NewData: React.FC = observer(() => {
    const {
        i18n,
        newData,
        unpublishedRecords,
        weeklyHours,
        positions,
    } = useErfaStore();
    const history = useHistory();

    const t = i18n.translate;
    weeklyHours.load();
    unpublishedRecords.load();

    const lastYear = moment().year() - 1;

    return (
        <ViewContainer>
            <>
                <HelpMessage t={t} type={"newData"} />
                {newData.isOpenModalQuestion && (
                    <SimpleModal
                        text={t("newData.publishAreYouSure")}
                        buttons={[
                            {
                                id: "okButton",
                                text: t("app.ok"),
                                type: "primary",
                            },
                            { id: "cancelButton", text: t("app.cancel") },
                        ]}
                        onButtonClick={async (id: string) => {
                            newData.setIsOpenModalQuestion(false);

                            if (id === "okButton") {
                                await newData.publish();
                                positions.de.unload();
                                positions.fr.unload();
                                positions.it.unload();
                            }
                        }}
                    />
                )}
                {newData.isOpenModalResult && (
                    <SimpleModal
                        text={t("newData.dataSuccessfullyPublished")}
                        buttons={[
                            {
                                id: "okButton",
                                text: t("app.ok"),
                                type: "primary",
                            },
                        ]}
                        onButtonClick={(_id: string) => {
                            newData.setIsOpenModalResult(false);
                            history.push("/filter");
                            newData.setPublished(true);
                        }}
                    />
                )}
                {newData.isOpenModalError && (
                    <SimpleModal
                        text={t("newData.publishFailed")}
                        buttons={[
                            {
                                id: "okButton",
                                text: t("app.ok"),
                                type: "primary",
                            },
                        ]}
                        onButtonClick={(_id: string) => {
                            newData.setIsOpenModalError(false);
                        }}
                    />
                )}
                <CollapsibleSegment
                    title={`${t("app.newData")} ${lastYear}`}
                    collapsible
                    open
                    footer={
                        <ResponsiveButton
                            label={t("newData.publish")}
                            primary
                            fluid
                            disabled={
                                !unpublishedRecords.items.length ||
                                unpublishedRecords.items.find(
                                    (record) =>
                                        record.positionId === undefined ||
                                        record.salaryBasis === undefined ||
                                        record.age === undefined ||
                                        record.yearsOfEmployment ===
                                            undefined ||
                                        record.degreeOfEmployment ===
                                            undefined ||
                                        record.contractSalary === undefined ||
                                        record.bonusSalary === undefined
                                ) !== undefined
                            }
                            onAction={() =>
                                newData.setIsOpenModalQuestion(true)
                            }
                        />
                    }
                >
                    {weeklyHours.loadState === "loaded" && (
                        <Form>
                            <Dropdown
                                value={weeklyHours.value}
                                required={true}
                                options={[
                                    { value: 42, display: "42" },
                                    { value: 43.5, display: "43.5" },
                                    { value: 45, display: "45" },
                                ]}
                                onChange={async (newValue) => {
                                    await weeklyHours.set(
                                        newValue as WeeklyHours
                                    );
                                }}
                                label={t("newData.weeklyHours")}
                                width={3}
                            />
                        </Form>
                    )}

                    <NewDataTable />
                </CollapsibleSegment>
            </>
        </ViewContainer>
    );
});
