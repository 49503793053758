import { IResultsTotalResponse } from "erfa-common";
import { flow, getRoot, Instance, SnapshotIn, types } from "mobx-state-tree";
import { createResultQueryParams } from "../../util/filterQueryParams";
import { ResultsTotalApi } from "../api";
import { IErfaModel } from "./Erfa";

export const Result = types.model({
    positionId: types.optional(types.number, 0),
    contractQ1: types.optional(types.number, 0),
    contractMedian: types.optional(types.number, 0),
    contractQ3: types.optional(types.number, 0),
    bonusQ1: types.optional(types.number, 0),
    bonusMedian: types.optional(types.number, 0),
    bonusQ3: types.optional(types.number, 0),
    quality: types.optional(types.string, ""),
});

export const ResultsTotalModel = types
    .model({
        items: types.optional(types.array(Result), []),
        loaded: types.optional(types.boolean, false),
        errorMessage: types.optional(types.string, ""),
    })
    .actions((self) => ({
        setResults(results: IResultsTotalResponse) {
            self.items.replace(
                results.map((result) => {
                    return {
                        positionId: result.position_id,
                        bonusQ1: result.bonusSalary["1st_quartil"],
                        bonusQ3: result.bonusSalary["3rd_quartil"],
                        bonusMedian: result.bonusSalary.median,
                        contractQ1: result.contractSalary["1st_quartil"],
                        contractQ3: result.contractSalary["3rd_quartil"],
                        contractMedian: result.contractSalary.median,
                        quality: result.quality,
                    };
                })
            );
        },
        setErrorMessage(message: string) {
            self.errorMessage = message;
        },
    }))
    .actions((self) => ({
        load: flow(function* () {
            if (!self.loaded) {
                const filterTotal = getRoot<IErfaModel>(self).filterTotal;
                let queryParams = createResultQueryParams(filterTotal.values);

                try {
                    const results = yield ResultsTotalApi.getCompanyResultsTotal(
                        queryParams
                    );
                    self.setResults(results);
                    self.setErrorMessage("");
                } catch (e) {
                    self.setErrorMessage("app.noResultsFound");
                } finally {
                    self.loaded = true;
                }
            }
        }),
        async reload() {
            self.loaded = false;
            await this.load();
        },
    }));

export const resultsTotalDefault: SnapshotIn<typeof ResultsTotalModel> = {
    items: [],
    loaded: false,
};

export type IResultTotalInstance = Instance<typeof Result>;
export type IResultsTotalInstance = Instance<typeof ResultsTotalModel>;
