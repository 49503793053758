import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { AppLayout, IVerticalMenuItem, VerticalMenu } from "components";
import { Filter, FilterTotal, NewData } from ".";
import logo from "../../img/mirus_logo_transparent.png";
import hsLogo from "../../img/hotellerieSuisse_logo_transparent.png";
import { observer } from "mobx-react-lite";
import _ from "lodash";
import { useErfaStore } from "../../Root";
import { LanguageSelect } from "../../erfa_components";
import FlexView from "react-flexview";

export const Routes: React.FC = observer(() => {
    const { i18n, newData } = useErfaStore();
    const t = i18n.translate;
    newData.loadPublished();

    const isPublished = newData.published || newData.hr30;

    const topMenuItems: Array<IVerticalMenuItem> = [
        { text: t("app.filter"), link: "/filter" },
        { text: t("app.filterTotal"), link: "/filter-total" },
        { text: t("app.newData"), link: "/new-data" },
    ];

    if (isPublished) {
        _.remove(
            topMenuItems,
            (menuItem) =>
                menuItem.link === "/new-data" &&
                menuItem.text === t("app.newData")
        );
    }

    const bottomMenuItems: IVerticalMenuItem[] = [
        { text: t("app.logout"), link: "/logout", icon: "sign out" },
    ];

    return isPublished !== null ? (
        <>
            <AppLayout>
                <AppLayout.Menu>
                    <VerticalMenu
                        topItems={topMenuItems}
                        bottomItems={bottomMenuItems}
                        logo={{
                            src: logo,
                            link: "https://www.mirus.ch",
                            linkTarget: "blank",
                        }}
                        subLogo={{
                            src: hsLogo,
                        }}
                    />
                </AppLayout.Menu>

                <AppLayout.Content>
                    <AppLayout.Header>
                        <LanguageSelect admin={false} />
                    </AppLayout.Header>
                    <AppLayout.Body>
                        <Switch>
                            <Route exact path="/">
                                <Redirect to={"/filter"} />
                            </Route>

                            <Route exact path="/filter">
                                <Filter />
                            </Route>

                            <Route exact path="/filter-total">
                                <FilterTotal />
                            </Route>

                            {!isPublished && (
                                <Route exact path="/new-data">
                                    <NewData />
                                </Route>
                            )}

                            <Route>
                                <Redirect to={"/not_found"} />
                            </Route>
                        </Switch>
                    </AppLayout.Body>
                    <AppLayout.Footer>
                        <FlexView hAlignContent={"center"}>
                            {`${t("app.emailContact")}:`}&nbsp;
                            <a href={"mailto:benchmark@hotelleriesuisse.ch"}>
                                benchmark@hotelleriesuisse.ch
                            </a>
                        </FlexView>
                    </AppLayout.Footer>
                </AppLayout.Content>
            </AppLayout>
        </>
    ) : (
        <></>
    );
});
