import React, { useCallback } from "react";
import { Table } from "components";
import { useAdminStore } from "../../Root";
import { useHistory } from "react-router-dom";
import { IAdminCustomer } from "../models";
import { observer } from "mobx-react-lite";
import { TableView } from "../../Framework/TableView";
import { AdminCustomerDetail } from "./details/AdminCustomerDetail";

export const AdminCustomers: React.FC = observer(() => {
    const adminStore = useAdminStore();
    const t = adminStore.i18n.translate;
    adminStore.customers.load();

    const history = useHistory();

    const onRowClick = useCallback(
        (row: object) => {
            history.push(`/admin/customers/${(row as IAdminCustomer).id}`);
        },
        [history]
    );

    return adminStore.customers.loaded ? (
        <TableView
            title={t("app.customers")}
            collection={adminStore.customers}
            canCreate
            translate={t}
        >
            <Table
                data={adminStore.customers.items.map(
                    ({ id, name, number, hr30 }) => ({
                        id,
                        name,
                        number,
                        hr30,
                    })
                )}
                noRowsMessage={t("app.tableNoRows")}
                onRowClick={onRowClick}
            >
                <Table.TextColumn column={"number"}>
                    {t("app.id")}
                </Table.TextColumn>
                <Table.TextColumn column={"name"} flex={1}>
                    {t("app.company")}
                </Table.TextColumn>
                <Table.CheckboxColumn column={"hr30"} flex={1}>
                    {"HR3.0"}
                </Table.CheckboxColumn>
            </Table>
            <AdminCustomerDetail translate={t} />
        </TableView>
    ) : (
        <></>
    );
});
