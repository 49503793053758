import React from "react";
import FlexView from "react-flexview";
import moment from "moment";
import { CollapsibleSegment, VerticalSpace } from "components";
import { observer } from "mobx-react-lite";
import { IErfaModel } from "../erfa/models";
import { IAdminModel } from "../admin/models";
import { ResultsAllTable, ResultsCompanyTable, ResultsSegmentMessage } from ".";
import { ResultsQuality } from "./ResultsQuality";

interface IProps {
    store: IErfaModel | IAdminModel;
}
export const ResultsSegment: React.FC<IProps> = observer(({ store }) => {
    const { i18n, results: resultsStore } = store;
    const t = i18n.translate;

    const lastYear = moment().year() - 1;

    return (
        <FlexView column grow>
            {resultsStore["company"] && (
                <>
                    <CollapsibleSegment
                        collapsible
                        open
                        title={`${t("results.myCompany")} ${lastYear}`}
                    >
                        {resultsStore["company"].errorMessage ? (
                            <ResultsSegmentMessage
                                text={t(resultsStore["company"].errorMessage)}
                            />
                        ) : (
                            <div style={{ minHeight: "10em" }}>
                                <ResultsQuality
                                    translate={t}
                                    quality={resultsStore["company"].quality}
                                />
                                <ResultsCompanyTable store={store} />
                            </div>
                        )}
                    </CollapsibleSegment>
                    <VerticalSpace small />
                </>
            )}

            <CollapsibleSegment
                collapsible
                open
                title={`${t("results.all")} ${lastYear}`}
            >
                {resultsStore.all.errorMessage ? (
                    <ResultsSegmentMessage
                        text={t(resultsStore.all.errorMessage)}
                    />
                ) : (
                    <div style={{ minHeight: "10em" }}>
                        <ResultsQuality
                            translate={t}
                            quality={resultsStore.all.quality!}
                        />
                        <ResultsAllTable store={store} />
                    </div>
                )}
            </CollapsibleSegment>
        </FlexView>
    );
});
