import { observer } from "mobx-react-lite";
import React from "react";
import { IAdminModel } from "../admin/models";
import { IErfaModel, IResultsInstance } from "../erfa/models";
import { createSalaryObject, ResultsTable } from "./ResultsTable";

interface IProps {
    store: IErfaModel | IAdminModel;
}

export const ResultsCompanyTable: React.FC<IProps> = observer(({ store }) => {
    const results = getResultsCompanyForSegment(
        store.results as IResultsInstance
    );
    return (
        <ResultsTable
            salary={results.salary}
            bonus={results.bonus}
            translate={store.i18n.translate}
        />
    );
});

const getResultsCompanyForSegment = (results: IResultsInstance) => ({
    salary: createSalaryObject(results.company.salary),
    bonus: createSalaryObject(results.company.bonus),
});
