import { DropdownValue, DropdownOption } from "components";
import moment from "moment";

type ValidateFunction = (value: string) => string;

export const emptyField = "app.inputMissing";
const invalidEmailFormat = "app.invalidEmailFormat";
const invalidDate = "app.invalidDate";

export const validate = (
    value: string,
    validateFunction: ValidateFunction,
    hook: (error: string) => void
) => {
    const error = validateFunction(value);
    hook(error);
    return error;
};

export const validateDropdown = (
    value: DropdownValue,
    options: DropdownOption[],
    hook: (error: string) => void
) => {
    const found = options.some((opt) => opt.value === value);

    const error = found ? "" : emptyField;
    hook(error);
    return error;
};

export const validateDate = (value: string | undefined) => {
    if (value === "DD.MM.YYYY" || value === undefined) {
        return emptyField;
    }
    const validDate = moment(value, "DD.MM.YYYY", true).isValid();

    if (!validDate) {
        return invalidDate;
    }
    return "";
};

export const validateRequired: ValidateFunction = (value) =>
    value ? "" : emptyField;

export const validateEmail: ValidateFunction = (value) => {
    if (!value) {
        return emptyField;
    }

    const regexEmailFormat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
    const emailRegex = new RegExp(regexEmailFormat);
    if (!emailRegex.test(value)) {
        return invalidEmailFormat;
    }

    return "";
};
