import {
    IModelType,
    ISimpleType,
    ModelProperties,
    ModelPropertiesDeclarationToProperties,
    types,
    _NotCustomized,
    getParent,
    hasParent,
    IArrayType,
    Instance,
    IOptionalIType,
    flow,
    isAlive,
} from "mobx-state-tree";
import {
    convertApiError,
    IValidationErrorKey,
    unknownError,
} from "../api/ErrorResponse";
import { IRestCollectionModel } from "./CollectionModel";
import { ExtractCFromProps, IMaybe } from "mobx-state-tree/dist/internal";

type RecordModelProps<
    K extends string extends K ? never : string,
    KT extends "string" | "number",
    T1 extends ModelProperties,
    T2 extends ModelProperties
> = {
    [P in K]: ISimpleType<KT extends "string" ? string : number>;
} &
    ModelPropertiesDeclarationToProperties<T1> &
    ModelPropertiesDeclarationToProperties<T2>;

export type IRecordModel<
    K extends string extends K ? never : string,
    KT extends "string" | "number",
    T1 extends ModelProperties,
    T2 extends ModelProperties
> = IModelType<
    RecordModelProps<K, KT, T1, T2>,
    {
        set: (values: ExtractCFromProps<T1>) => void;
    },
    _NotCustomized,
    _NotCustomized
>;

export type IRestRecordModel<
    K extends string extends K ? never : string,
    KT extends "string" | "number",
    T1 extends ModelProperties,
    T2 extends ModelProperties
> = IModelType<
    RecordModelProps<K, KT, T1, T2>,
    {
        set: (values: ExtractCFromProps<T1>) => Promise<boolean>;
        remove: () => Promise<void>;
        getParentIds(): (number | string)[];
        clearErrors(): void;
    },
    _NotCustomized,
    _NotCustomized
>;

export type IRestSingleRecordModel<
    K extends string extends K ? never : string,
    KT extends "string" | "number",
    T1 extends ModelProperties,
    T2 extends ModelProperties
> = IModelType<
    {
        item: IMaybe<IRestRecordModel<K, KT, T1, T2>>;
        loaded: IOptionalIType<ISimpleType<boolean>, [undefined]>;
        errors: IOptionalIType<IArrayType<ISimpleType<string>>, [undefined]>;
    },
    {
        load: () => Promise<void>;
        getParentIds(): (number | string)[];
    },
    _NotCustomized,
    _NotCustomized
>;

export type KeyType<KT extends "string" | "number"> = KT extends "string"
    ? string
    : number;

type KeyProp<T> = T extends IRestRecordModel<infer K, infer KT, any, any>
    ? { [P in K]: ISimpleType<KeyType<KT>> }
    : T extends IRecordModel<infer K, infer KT, any, any>
    ? { [P in K]: ISimpleType<KeyType<KT>> }
    : never;

export type ModelInterface<T> = T extends IRestRecordModel<
    infer _Key,
    any,
    infer Att,
    any
>
    ? ExtractCFromProps<KeyProp<T> & Att>
    : T extends IRecordModel<infer _Key, any, infer Att, any>
    ? ExtractCFromProps<KeyProp<T> & Att>
    : never;

type Attributes<T> = T extends IRestRecordModel<infer _K, any, infer T1, any>
    ? T1
    : T extends IRecordModel<infer _K, any, infer T1, any>
    ? T1
    : never;

export type CreateRecordType<T> = T extends IRecordModel<
    infer _K,
    any,
    any,
    any
>
    ? ExtractCFromProps<Attributes<T>>
    : never;

export type RecordType<T> = T extends IRecordModel<infer _K, any, any, any>
    ? ExtractCFromProps<KeyProp<T> & Attributes<T>>
    : never;

type IRestApi<T> = T extends IRecordModel<infer _K, any, any, any>
    ? {
          errorMap: Map<IValidationErrorKey, string>;
          update: (
              ids: (number | string)[],
              values: CreateRecordType<T>
          ) => Promise<RecordType<T>>;
      }
    : never;

type ISingleRestApi<T> = T extends IRecordModel<infer _K, any, any, any>
    ? {
          errorMap: Map<IValidationErrorKey, string>;
          update: (
              ids: (number | string)[],
              values: CreateRecordType<T>
          ) => Promise<RecordType<T>>;
          get: (ids: (number | string)[]) => Promise<RecordType<T>>;
      }
    : never;

export function createRecordModel<
    K extends string extends K ? never : string,
    KT extends "string" | "number",
    T1 extends ModelProperties,
    T2 extends ModelProperties
>(
    key: K,
    keyType: KT,
    attributes: T1,
    otherAttributes: T2
): IRecordModel<K, KT, T1, T2> {
    return types
        .model({
            [key]:
                keyType === "string"
                    ? types.identifier
                    : types.identifierNumber,
            ...attributes,
            ...otherAttributes,
        })
        .actions((self) => ({
            set(values: ExtractCFromProps<T1>) {
                for (const p in attributes) {
                    if (p in values) {
                        self[p] = values[p] as any;
                    }
                }
            },
        }));
}

export function createRestRecordModel<
    K extends string extends K ? never : string,
    KT extends "string" | "number",
    T1 extends ModelProperties,
    T2 extends ModelProperties
>(
    key: K,
    keyType: KT,
    attributes: T1,
    otherAttributes: T2,
    api: IRestApi<IRestRecordModel<K, KT, T1, T2>>
): IRestRecordModel<K, KT, T1, T2> {
    return createRecordModel(key, keyType, attributes, otherAttributes)
        .props({
            errors: types.optional(types.array(types.string), []),
        })
        .views((self) => ({
            getParentIds: () => {
                if (hasParent(self, 2)) {
                    const parent = getParent(self, 2) as Instance<
                        IRestRecordModel<"id", any, any, any>
                    >;
                    if (parent.getParentIds) {
                        return [...parent.getParentIds(), self[key]];
                    }
                }
                return [self[key]];
            },
        }))
        .actions((self) => {
            const superSet = self.set;
            return {
                set: flow(function* set(values: ExtractCFromProps<T1>) {
                    try {
                        yield api.update(self.getParentIds(), values);
                    } catch (e) {
                        if (e.response && isAlive(self)) {
                            const { status, data } = e.response;
                            self.errors.replace(
                                convertApiError(status, data, api.errorMap)
                            );
                        } else {
                            console.log(e);
                            self.errors.replace(["app.unknownError"]);
                        }
                        return false;
                    }

                    superSet(values);
                    self.errors.replace([]);
                    return true;
                }),
                remove: flow(function* () {
                    const collection = getParent(self, 2) as Instance<
                        IRestCollectionModel<"id", any, any>
                    >;
                    yield collection.removeById(self[key]);
                }),
                clearErrors: () => {
                    self.errors.replace([]);
                },
            };
        });
}

export function createRestSingleRecordModel<
    K extends string extends K ? never : string,
    KT extends "string" | "number",
    T1 extends ModelProperties,
    T2 extends ModelProperties
>(
    key: K,
    keyType: KT,
    attributes: T1,
    otherAttributes: T2,
    api: ISingleRestApi<IRestRecordModel<K, KT, T1, T2>>
): IRestSingleRecordModel<K, KT, T1, T2> {
    const recordModel: IRestRecordModel<K, KT, T1, T2> = createRestRecordModel(
        key,
        keyType,
        attributes,
        otherAttributes,
        {
            errorMap: api.errorMap,
            update: api.update,
        }
    );

    return types
        .model({
            item: types.maybe(recordModel),
            loaded: types.optional(types.boolean, false),
            errors: types.optional(types.array(types.string), []),
        })
        .views((self) => ({
            getParentIds: () => {
                const parent = getParent(self) as Instance<
                    IRestRecordModel<"id", any, any, any>
                >;
                if (parent && parent.getParentIds) {
                    return parent.getParentIds();
                }
                return [];
            },
        }))
        .actions((self) => ({
            load: flow(function* () {
                if (!self.loaded) {
                    let values: RecordType<
                        IRestRecordModel<K, KT, T1, T2>
                    > | null = null;
                    try {
                        values = yield api.get(self.getParentIds());
                    } catch (e) {
                        if (e.response && isAlive(self)) {
                            const { status, data } = e.response;
                            self.errors.replace(
                                convertApiError(status, data, api.errorMap)
                            );
                        } else {
                            console.log(e);
                            self.errors.replace([unknownError]);
                        }
                        return;
                    }
                    if (values) {
                        self.item = recordModel.create(values as any);
                        self.errors.replace([]);
                        self.loaded = true;
                    }
                }
            }),
        }));
}
