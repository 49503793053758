import React from "react";
import _ from "lodash";

type ComponentFunc<P> = (props: P) => React.ReactElement<P> | null;

export const extractComponent = (
    component: React.ReactElement<any>,
    expectedTypes: Record<string, ComponentFunc<any>>,
    depthOfSearch?: number
): Record<string, React.ReactElement | null> => {
    const result: Record<string, React.ReactElement | null> = _.mapValues(
        expectedTypes,
        () => null
    );

    for (let prop in expectedTypes) {
        const expectedComponent = expectedTypes[prop];
        if (component.type === expectedComponent) {
            result[prop] = component;
            return result;
        }
    }

    if (!depthOfSearch) {
        throw new Error("Unexpected subcomponent");
    }

    const componentFunc = component.type as (props: {}) => React.ReactElement | null;
    const renderedComponent = componentFunc(
        component.props
    ) as React.ReactElement;

    return extractComponent(
        renderedComponent,
        expectedTypes,
        depthOfSearch - 1
    );
};
