import { flow, getParent, Instance, types } from "mobx-state-tree";
import {
    IAdminCustomerUserResponse,
    IAdminCustomerUsersRequest,
    StarCategoryArray,
    TourismRegionArray,
    TourismZoneArray,
} from "erfa-common";
import { createRestCollectionModel } from "../../Framework/models/CollectionModel";
import {
    createRestRecordModel,
    ModelInterface,
} from "../../Framework/models/RecordModel";
import {
    AdminCustomerPublishedApi,
    AdminCustomersApi,
    AdminCustomerUserApi as AdminCustomerUsersApi,
} from "../api";
import { adminCustomerErrors } from "../api/AdminCustomersErrors";
import { adminCustomerUserErrors } from "../api/AdminCustomerUserErrors";

export const PublishedYear = createRestRecordModel(
    "year",
    "number",
    {},
    {},
    {
        errorMap: new Map(),
        update: () => {
            throw new Error("update not supported");
        },
    }
);

export const PublishedYears = createRestCollectionModel(
    PublishedYear,
    "year",
    "number",
    {
        resource: "year",
        errorMap: new Map(),
        all: (ids) => AdminCustomerPublishedApi.allByCustomer(ids[0] as number),
        remove: (ids) =>
            AdminCustomerPublishedApi.deleteYear(
                ids[0] as number,
                ids[1] as number
            ),
        create: () => {
            throw new Error("create not supported");
        },
    }
);

const AdminCustomerUserActions = types.model({}).actions((self) => ({
    setPassword: flow(function* (password: string) {
        const customerUser = getParent(self);

        const customer = getParent(getParent(getParent(customerUser)));
        yield AdminCustomerUsersApi.setPassword(
            customer["id"] as number,
            customerUser["id"] as number,
            {
                password,
            }
        );
    }),
}));

export const AdminCustomerUser = createRestRecordModel(
    "id",
    "number",
    {
        username: types.string,
        firstname: types.string,
        lastname: types.string,
        password: types.maybe(types.string),
        passwordAgain: types.maybe(types.string),
    },
    {
        actions: types.optional(AdminCustomerUserActions, {}),
    },
    {
        errorMap: adminCustomerUserErrors,
        update: (ids, { firstname, lastname, username }) =>
            AdminCustomerUsersApi.update(ids[0] as number, ids[1] as number, {
                firstname,
                lastname,
                username,
            }).then(({ id, firstname, lastname, username }) => {
                return {
                    id,
                    firstname,
                    lastname,
                    username,
                    password: undefined,
                    passwordAgain: undefined,
                };
            }),
    }
);

const convertResponseItem = (item: IAdminCustomerUserResponse) => ({
    ...item,
    password: undefined,
    passwordAgain: undefined,
});

export const AdminCustomerUsers = createRestCollectionModel(
    AdminCustomerUser,
    "id",
    "number",
    {
        errorMap: adminCustomerErrors,
        resource: "customer",
        all: async (ids) =>
            (await AdminCustomerUsersApi.all(ids[0] as number)).map(
                convertResponseItem
            ),
        remove: (ids) =>
            AdminCustomerUsersApi.delete(ids[0] as number, ids[1] as number),
        create: async (ids, { firstname, lastname, username, password }) =>
            convertResponseItem(
                await AdminCustomerUsersApi.add(
                    ids[0] as number,
                    {
                        firstname,
                        lastname,
                        username,
                        password,
                    } as IAdminCustomerUsersRequest
                )
            ),
    }
);

export const AdminCustomer = createRestRecordModel(
    "id",
    "number",
    {
        name: types.string,
        number: types.string,
        star_category: types.maybe(types.enumeration([...StarCategoryArray])),
        tourism_region: types.maybe(types.enumeration([...TourismRegionArray])),
        tourism_zone: types.maybe(types.enumeration([...TourismZoneArray])),
        hr30: types.optional(types.boolean, false),
    },
    {
        publishedYears: types.optional(PublishedYears, {}),
        adminCustomerUsers: types.optional(AdminCustomerUsers, {}),
    },
    {
        errorMap: adminCustomerErrors,
        update: async (ids, values) => {
            const {
                id,
                name,
                number,
                star_category,
                tourism_region,
                tourism_zone,
                hr30,
            } = await AdminCustomersApi.update(ids[0] as number, {
                name: values.name,
                number: values.number,
                star_category: values.star_category || undefined,
                tourism_region: values.tourism_region || undefined,
                tourism_zone: values.tourism_zone || undefined,
                hr30: values.hr30 ?? false,
            });
            return {
                id,
                name,
                number,
                star_category,
                tourism_region,
                tourism_zone,
                hr30,
            };
        },
    }
);

export const AdminCustomers = createRestCollectionModel(
    AdminCustomer,
    "id",
    "number",
    {
        resource: "customer",
        errorMap: adminCustomerErrors,
        all: async () => {
            const result = await AdminCustomersApi.all();
            return result.map(
                ({
                    id,
                    name,
                    number,
                    star_category,
                    tourism_region,
                    tourism_zone,
                    hr30,
                }) => ({
                    id,
                    name,
                    number,
                    star_category,
                    tourism_region,
                    tourism_zone,
                    hr30,
                })
            );
        },
        remove: (ids) => AdminCustomersApi.delete(ids[0] as number),
        create: async (_ids, values) => {
            const {
                id,
                name,
                number,
                star_category,
                tourism_region,
                tourism_zone,
                hr30,
            } = await AdminCustomersApi.add({
                name: values.name,
                number: values.number,
                star_category: values.star_category || undefined,
                tourism_region: values.tourism_region || undefined,
                tourism_zone: values.tourism_zone || undefined,
                hr30: values.hr30 ?? false,
            });
            return {
                id,
                name,
                number,
                star_category,
                tourism_region,
                tourism_zone,
                hr30,
            };
        },
    }
);

export type IAdminCustomer = ModelInterface<typeof AdminCustomer>;
export type IAdminCustomerInstance = Instance<typeof AdminCustomer>;
export type IPublishedYearsInstance = Instance<typeof PublishedYears>;
export type IAdminCustomerUserInstance = Instance<typeof AdminCustomerUser>;
export type IAdminCustomerUsersInstance = Instance<typeof AdminCustomerUsers>;
export type IPublishedYear = ModelInterface<typeof PublishedYear>;
