import { observer } from "mobx-react-lite";
import React from "react";
import { IAdminModel } from "../admin/models";
import { IAdminResultsInstance } from "../admin/models/Results";
import { IErfaModel, IResultsInstance } from "../erfa/models";
import { createSalaryObject, ResultsTable } from "./ResultsTable";

interface IProps {
    store: IErfaModel | IAdminModel;
}

export const ResultsAllTable: React.FC<IProps> = observer(({ store }) => {
    const results = getResultsAllForSegment(store.results);
    return (
        <ResultsTable
            salary={results.salary}
            bonus={results.bonus}
            translate={store.i18n.translate}
        />
    );
});

const getResultsAllForSegment = (
    results: IResultsInstance | IAdminResultsInstance
) => ({
    salary: createSalaryObject(results.all.salary),
    bonus: createSalaryObject(results.all.bonus),
});
