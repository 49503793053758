import React from "react";
import { Table } from "semantic-ui-react";
import { m4PrimaryColor } from "./styles/color";
import { IResultInstance } from "../erfa/models";

interface IResultsTableProps {
    salary: ITableResults;
    bonus: ITableResults;
    translate: (key: string) => string;
}

export interface ITableResults {
    min: number;
    q1: number;
    median: number;
    q3: number;
    max: number;
}

export interface IColumnResult {
    key: string;
    value: string;
}

export const borderStyle = `solid 1.7px ${m4PrimaryColor}`;

export const ResultsTable: React.FC<IResultsTableProps> = ({
    salary,
    bonus,
    translate,
}) => {
    const t = translate;
    const columns: IColumnResult[] = [
        { value: t("results.min"), key: "min" },
        { value: t("results.quartile1"), key: "q1" },
        { value: t("results.median"), key: "median" },
        { value: t("results.quartile3"), key: "q3" },
        { value: t("results.max"), key: "max" },
    ];

    return (
        <Table
            unstackable
            style={{ minWidth: "36em" }}
            celled
            columns={6}
            basic={"very"}
        >
            <Table.Body>
                <Table.Row
                    textAlign={"right"}
                    verticalAlign="middle"
                    style={{ fontWeight: "bold" }}
                >
                    <Table.Cell
                        key={"headerCell_0"}
                        style={{
                            borderBottom: borderStyle,
                        }}
                    />
                    {columns.map((header, index) => (
                        <Table.Cell
                            key={`headerCell_${index + 1}`}
                            style={{
                                borderBottom: borderStyle,
                            }}
                        >
                            {header.value}
                        </Table.Cell>
                    ))}
                </Table.Row>

                <ResultsRow
                    columns={columns}
                    data={salary}
                    rowName={t("results.contractSalary")}
                />
                <ResultsRow
                    columns={columns}
                    data={bonus}
                    rowName={t("results.bonus")}
                />
            </Table.Body>
        </Table>
    );
};

const ResultsRow: React.FC<{
    columns: IColumnResult[];
    data: ITableResults;
    rowName: string;
}> = ({ columns, data, rowName }) => (
    <Table.Row>
        <Table.Cell key={`${rowName}_0`}>{rowName}</Table.Cell>
        {columns.map(({ key }, index) => (
            <Table.Cell key={`${rowName}_${index + 1}`} textAlign={"right"}>
                {data[key]}
            </Table.Cell>
        ))}
    </Table.Row>
);

export const createSalaryObject = (salary: IResultInstance) => {
    return {
        min: salary.min,
        max: salary.max,
        q1: salary.firstQuartil,
        q3: salary.thirdQuartil,
        median: salary.median,
    };
};
