import { IFilterInstance, IFilterTotalInstance } from "../erfa/models";

export const createResultQueryParams = (
    values: IFilterInstance | IFilterTotalInstance
) => {
    const params: string[] = [];

    if ("positionId" in values) {
        params.push(`position-id=${values.positionId}`);
    }

    values.salaryBasis.forEach((salary) =>
        params.push(`salary-basis[]=${salary}`)
    );

    values.ageBracket.forEach((age) => params.push(`age-bracket[]=${age}`));

    values.yearsOfEmployment.forEach((seniority) =>
        params.push(`years-of-employment[]=${seniority}`)
    );

    values.degreeOfEmployment.forEach((degree) =>
        params.push(`degree-of-employment[]=${degree}`)
    );

    values.starCategory.forEach((rating) =>
        params.push(`star-category[]=${rating}`)
    );

    values.tourismRegion.forEach((region) =>
        params.push(`tourism-region[]=${region}`)
    );

    values.tourismZone.forEach((zone) => params.push(`tourism-zone[]=${zone}`));

    return params.join("&");
};
