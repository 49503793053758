import React from "react";
import { DropdownOption } from "components";
import { TourismRegionArray, TourismZoneArray } from "erfa-common";
import { createDetail } from "../../../Framework/Detail";
import { validateRequired } from "../../../util/validations";
import { AdminCustomer, IAdminCustomerInstance } from "../../models";

interface IAdminCustomerIProps {
    adminCustomer?: IAdminCustomerInstance;
    translate: (key: string) => string;
}

export const AdminCustomerDetail: React.FC<IAdminCustomerIProps> = ({
    adminCustomer,
    translate: t,
}) => {
    const AdminCustomerDetail = createDetail(AdminCustomer);

    const tourismRegionOptions = TourismRegionArray.map(
        (tourismRegion): DropdownOption => ({
            display: t(`tourismusregion.${tourismRegion}`),
            value: tourismRegion,
        })
    );

    const tourismZoneOptions = TourismZoneArray.map(
        (tourismZone): DropdownOption => ({
            display: t(`tourismuszone.${tourismZone}`),
            value: tourismZone,
        })
    );

    return (
        <AdminCustomerDetail
            headerDataprovider="name"
            title={t("app.customer")}
            model={adminCustomer}
            canEdit={true}
            canDelete={true}
            translate={t}
        >
            <AdminCustomerDetail.TextField
                dataprovider="number"
                label={t("app.id")}
                maxChars={50}
                required
                onValidate={(value: string) => t(validateRequired(value))}
            />

            <AdminCustomerDetail.TextField
                dataprovider="name"
                label={t("app.company")}
                maxChars={200}
                required
                onValidate={(value: string) => t(validateRequired(value))}
            />

            <AdminCustomerDetail.StarCategoryDropdown
                dataprovider="star_category"
                label={t("filter.starRating")}
                required={false}
            />

            <AdminCustomerDetail.Dropdown
                dataprovider="tourism_region"
                label={t("filter.tourismRegion")}
                required={false}
                options={tourismRegionOptions}
            />

            <AdminCustomerDetail.Dropdown
                dataprovider="tourism_zone"
                label={t("filter.tourismZone")}
                required={false}
                options={tourismZoneOptions}
            />

            <AdminCustomerDetail.Checkbox
                dataprovider="hr30"
                label={"HR3.0"}
                required={false}
            />
        </AdminCustomerDetail>
    );
};
